<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          <span v-if="entite !=null">{{ entite.text }}</span>
          <span v-if="departement !=null"> > {{ departement.text }}</span>
          <span v-if="quartier !=null"> > {{ quartier.text }}</span>
          <span v-if="zone !=null"> > {{ zone.text }}</span>
        </h4>
      </div>
    </div>
  </div>

  <div class="row d-print-none">
    <div class="col-md-6">
      <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
    </div>
    <div class="col-md-6">
      <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
    </div>

  </div>
  <div class="row mt-2 d-print-none">
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
    </div>
<!--    <div class="col-md-4">
      <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectSecteur($event)" @change="changeSecteur($event)"  />
    </div>-->
    <div class="col-md-4">

      <input type="text" class="form-control" v-model="nombre"/>
    </div>
    <div class="col-md-2 text-start">

      <button @click="generateCard" class="btn  btn-success">Générer</button>

    </div>
  </div>
<!--  <div class="row mt-3">
    <div class="col-xl-12">
      <div class="card crm-widget">
        <div class="card-body p-0">
          <div class="row row-cols-xxl-5">
            <div class="col text-start bg-warning">
              <div class="py-4 px-3">
                <h5 class=" text-uppercase fs-13">Contribuable(s) enrôlé(s) </h5>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="ri-user-3-line display-6 text-white"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h2 class="mb-0"><span class="counter-value" data-target="{{ formattedNumber(dashboard.contribuables) }}"><router-link style="color: #fff" :to="{name: 'listeEquipements'}">{{ formattedNumber(dashboard.contribuables) }}</router-link></span></h2>
                  </div>
                </div>
              </div>
            </div>&lt;!&ndash; end col &ndash;&gt;
            <div class="col text-start bg-info">
              <div class="py-4 px-3 text-white">
                <h5 class="text-uppercase fs-13 text-white">Assiète </h5>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="ri-money-dollar-box-line display-6 text-white"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    &lt;!&ndash;                    <h2 class="mb-0"><span class="counter-value text-white" data-target="{{dashboard.prevu}}">{{ formattedNumber(dashboard.prevu) }}</span></h2>&ndash;&gt;
                    <h2>
                      <router-link style="color: #fff"
                                   :to="{name: 'collecte', query: {'entite': JSON.stringify(ids), 'ms': JSON.stringify(this.ms), from: this.from, to: this.to} }">
                        {{ formattedNumber(Number(dashboard.prevu))}}
                      </router-link>
                    </h2>
                  </div>
                </div>
              </div>
            </div>&lt;!&ndash; end col &ndash;&gt;
            <div class="col text-start bg-danger">
              <div class="py-4 px-3">
                <h5 class=" text-uppercase fs-13 text-white">Montant Restant </h5>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="ri-money-dollar-box-line display-6 text-white"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    &lt;!&ndash;                    <h2 class="mb-0"><span class="counter-value text-white" data-target="{{dashboard.prevu-dashboard.paiement}}">{{ formattedNumber(Math.round((dashboard.prevu-(Number(dashboard.paiement) - ((Number(dashboard.paiement)*25))/100)) ))}}</span></h2>&ndash;&gt;
                    <h2>
                      <router-link
                          style="color: #fff"
                          :to="{name: 'detailsContribuables', query: {'entite': this.entite, 'ms': JSON.stringify(this.ms), from: this.from, to: this.to} }">
                        {{formattedNumber(Number((Number(dashboard.prevu) - Number(dashboard.paiement))))}}
                      </router-link>
                    </h2>
                  </div>
                </div>
              </div>
            </div>&lt;!&ndash; end col &ndash;&gt;
            <div class="col text-start" style="background: #04414d">
              <div class="py-4 px-3">
                <h5 class=" text-uppercase fs-13 text-white">Part NTA </h5>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="ri-money-dollar-box-line display-6 text-white"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <h2 class="mb-0" style="color: #fff"><span style="color: #fff !important;" class="counter-value text-white" data-target="{{dashboard.paiement}}">{{ formattedNumber(Math.round((Number(dashboard.paiement)*25)/100))}}</span></h2>

                  </div>
                </div>
              </div>
            </div>&lt;!&ndash; end col &ndash;&gt;
            <div class="col text-start bg-success">
              <div class="py-4 px-3">
                <h5 class="text-uppercase fs-13 text-white">Montant payé <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <i class="ri-money-dollar-box-line display-6 text-white"></i>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    &lt;!&ndash;                    <h2 class="mb-0"><span class="counter-value text-white" data-target="197">{{ formattedNumber(totalPayer) }}</span></h2>&ndash;&gt;
                    <h2>
                      <router-link
                          style="color: #fff"
                          :to="{name: 'listeTransactions', query: {'entite': (this.entite != null)?this.entite.id:null, 'ms': JSON.stringify(this.ms), from: this.from, to: this.to} }">
                        {{ formattedNumber(Number(dashboard.paiement)) }}
                      </router-link>
                    </h2>
                  </div>
                </div>
              </div>
            </div>&lt;!&ndash; end col &ndash;&gt;
          </div>&lt;!&ndash; end row &ndash;&gt;
        </div>&lt;!&ndash; end card body &ndash;&gt;
      </div>&lt;!&ndash; end card &ndash;&gt;
    </div>&lt;!&ndash; end col &ndash;&gt;
  </div>-->
<!--  <div class="row mt-3">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-bordered text-start text-center">
            <thead>
            <tr class=" ">
              <th class="bg-primary text-white text-center" rowspan="2">Ramification</th>
              <th class="bg-warning">Enrôlement</th>
              <th class="bg-info" style="vertical-align: top">Prévu</th>
              <th class="bg-success">Payé</th>
              <th  class="bg-danger">Montant <br> Restant</th>

            </tr>
            </thead>
            <tbody >
            <tr v-for="item in tableau" :key="item">
              <td style="font-weight: bold" class="text-center bold bg-primary text-white ">{{item.nom}}</td>
              <td style="font-weight: bold"  class="text-end bg-warning">{{formattedNumber(item.contribuables)}}</td>
              &lt;!&ndash;<td>0</td>&ndash;&gt;
              <td style="font-weight: bold"  class="text-end bg-info">{{formattedNumber(item.prevu)}}</td>
              <td style="font-weight: bold"  class="text-end bg-success">{{ formattedNumber(Number(item.paiement)) }}</td>
              <td style="font-weight: bold"  class="text-end bg-danger">{{formattedNumber(Number((Number(item.prevu) - Number(item.paiement))))}}</td>



            </tr>
            </tbody>
            <tfoot class=" ">
            <tr class="">
              <th class="bg-primary text-white">TOTAL</th>
              <th class="text-end bg-warning">{{formattedNumber(totalContribuable)}}</th>
              <th class="text-end bg-info">{{formattedNumber(totalPrevu)}}</th>

              <th class="text-end bg-success">{{formattedNumber(totalPaiement)}}</th>
              <th class="text-end bg-danger">{{formattedNumber(Number(totalRestant))}}</th>


            </tr>
            </tfoot>
          </table>

        </div>
      </div>
    </div>

  </div>-->
  <div class="row mt-4">
    <div class="print-container">
      <div class="page">
        <div class="card" v-for="(card, index) in listCartes" :key="index" style="border: 3px solid black;">
          <div class="card-content">
            <img class="card-image" src="../../assets/images/logobko.png"  style="float: left" alt="Image" >
            <br>
            <h6 class="texte">District de Bamako</h6>
            <h6>{{ml.text}}</h6>
            <br><br><br>
            <h1>Carte provisoire</h1>
            <h3 class="card-number">{{ card.code }}</h3>
            <div>
              <qr-code :text="card.code" size="130" class="qrcode"></qr-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Select2 from 'vue3-select2-component';
import EntiteRepository from "@/repository/EntiteRepository";
import RamificationRepository from "@/repository/RamificationRepository";
import DashboardRepository from "@/repository/DashboardRepository";
import Loading from "vue-loading-overlay";
import moment from "moment";
export default {
  name: 'DashboardPage',
  computed: {

    config(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        // locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee +"-" +new Date().getMonth()+"-01", this.selectedAnnee +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },

  },
  data(){
    return {
      isLoading: false,
      fullPage: true,
      ml: {},
      selectedCollectivite: null,
      user: null,
      nombre: null,
      cards: [],
      listCartes: [],
      ids: [],
      dashboard: {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0,
        contribuableActuel: 0,
        prevuActuel: 0,
        paiementActuel:0
      },
      tableau: [],
      montantTotal: 0,
      entite: null,
      ms: [],
      from: '',
      to: '',
      agentChat: [],
      selectedAnnee: null,
      departement: null,
      quartier: null,
      zone:null,
      secteur: null,
      selectedEntite: null,
      daterange: null,
      selectedDepartement: null,
      selectedQuartier: null,
      selectedZone: null,
      selectedSecteur: null,
      ramificationRepo: new RamificationRepository(),
      dashboardRepo: new DashboardRepository(),
      entiteRepo: new EntiteRepository(),
      myValue: '',
      listCollectivites: [],
      listDepartements: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],

    }
  },
  mounted() {
    //  this.generateCards();
    setInterval(() => {
      if (this.numeroCarte < 1000) {
        this.numeroCarte++;
      } else {
        this.numeroCarte = 1;
      }
    }, 1000);
    if (window.matchMedia) {
      const mediaQueryList = window.matchMedia('print');
      this.isPreview = mediaQueryList.matches;
      mediaQueryList.addListener((mql) => {
        this.isPreview = mql.matches;
      });
    }

  },
  methods:{

    onDateChange(){
      this.refresh();
    },
    generateCard() {
      if (this.selectedEntite == null) {
        alert('Veuillez selectionner une entité')
      }
      if (this.selectedDepartement == null) {
        alert('Veuillez selectionner un département')
      }
      if (this.selectedQuartier == null) {
        alert('Veuillez selectionner un quartier')
      }
      if (this.nombre == null) {
        alert('Veuillez saisir le nombre de carte')
      }
      if (this.nombre != null && this.selectedEntite != null&& this.selectedQuartier != null) {

        this.entiteRepo.findOne("/v2/cartes/bulk-creation?entite=" + this.selectedEntite + "&ramification="+this.selectedQuartier+"&nombre=" + this.nombre)
            .then(response => {
              this.listCartes = response;
            });

      }
      console.log(this.selectedQuartier);

    },
    refresh(){
      if (this.entite === null ){
        this.dashboardRepo.dashboardByEntite(this.ids, null, this.ms, this.from, this.to, this.selectedAnnee)
            .then(response => {
              this.dashboard = response.stats;
              this.tableau = response.details;
              this.isLoading =false;
            });
      }
      if (this.entite != null && this.departement === null)
        this.dashboardRepo.dashboardByEntite(this.entite.id, null , null, this.from, this.to, this.selectedAnnee)
            .then(response => {
              this.dashboard = response.stats;
              this.tableau = response.details;
              this.isLoading =false;

            })
      if (this.entite != null && this.departement != null && this.quartier == null){
        this.dashboardRepo.dashboardByEntite(this.entite.id,this.departement.id, this.ms, this.from, this.to, this.selectedAnnee)
            .then(response => {
              this.dashboard = response.stats;
              this.tableau = response.details;
              this.isLoading =false;

            })
      }
      if (this.entite != null && this.departement != null && this.quartier != null && this.zone === null){
        this.dashboardRepo.dashboardByEntite(this.entite.id,this.quartier.id, this.ms, this.from, this.to, this.selectedAnnee)
            .then(response => {
              this.dashboard = response.stats;
              this.tableau = response.details;
              this.isLoading =false;

            })
      }
      if (this.entite != null && this.departement != null && this.quartier != null && this.zone != null && this.secteur === null){
        this.dashboardRepo.dashboardByEntite(this.entite.id,this.zone.id, this.ms, this.from, this.to, this.selectedAnnee)
            .then(response => {
              this.dashboard = response.stats;
              this.tableau = response.details;
              this.isLoading =false;

            })
      }
      if (this.entite != null && this.departement != null && this.quartier != null && this.zone != null && this.secteur != null){
        this.dashboardRepo.dashboardByEntite(this.entite.id,this.secteur.id, this.ms, this.from, this.to, this.selectedAnnee)
            .then(response => {
              this.dashboard = response.stats;
              this.tableau = response.details;
              this.isLoading =false;

            })
      }
    },
    selectEntite(val){
      this.entite = val;
      this.isLoading=true;
      this.departement = null;
      this.ids = [];
      this.ids.push(val.id)
      //  this.selectedEntite = val;
      if (this.user.ramifications.length > 0){
        this.user.ramifications.forEach(item => {
          if(item.type.nom === 'Quartier'){
            this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'ram.id', operator: 'eq', value: item.parent.id}], 'ent.nom', 'asc', 30, 1)
                .then(response => {
                  response.data.forEach(item => {
                    this.listDepartements.push({id: item.id, text: item.nom});
                    // this.isLoading=false;
                    this.refresh();
                  })
                });
          }
          if(item.type.nom === 'Zone'){
            this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'ram.id', operator: 'eq', value: item.parent.parent.id}], 'ent.nom', 'asc', 30, 1)
                .then(response => {
                  response.data.forEach(item => {
                    this.listDepartements.push({id: item.id, text: item.nom});
                    // this.isLoading=false;
                    this.refresh();
                  })
                });
          }
        })
      }else{
        this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null', value: ''}], 'ent.nom', 'asc', 30, 1)
            .then(response => {
              response.data.forEach(item => {
                this.listDepartements.push({id: item.id, text: item.nom});
                // this.isLoading=false;
                this.refresh();
              })
            });
      }

    },
    changeEntite(val){
      this.entite = val;
      this.selectedEntite = val;
    },
    changeDepartement(val) {
      this.selectedDepartement = val;
    },
    async selectDepartement(val) {
      this.isLoading = true;
      this.ms = [];
      this.departement = val;
      this.quartier = null;
      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      this.ms.push(val.id.toString())
      if (this.user.ramifications.length > 0) {
        this.user.ramifications.forEach(item => {
          if(item.type.nom === 'Quartier'){
            this.listQuartiers.push({id: item.id, text: item.nom})
            this.ms = this.listQuartiers.map(item => item.id);
            this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.ms}], 'ent.nom', 'asc', 3000, 1)
                .then(zone => {
                  zone.data.forEach(item => {
                    this.ms.push(item.id.toString())
                  })
                  this.listZones = zone.data.map(item => ({id: item.id, text: item.nom}));

                  this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.nom', 'asc', 3000, 1)
                      .then(secteurs => {
                        secteurs.data.forEach(item => {
                          this.ms.push(item.id)
                        })
                        this.isLoading = false

                        this.refresh();

                      })



                })
          }
          if (item.type.nom === 'Zone'){
            this.ramificationRepo.search([{colonne: 'ram.id', operator: 'eq', value: item.parent.parent.id}], 'ent.nom', 'asc', 3000, 1)
                .then(quartier => {
                  this.listQuartiers.push({id: quartier.id, text: quartier.nom})
                  this.ms = this.listQuartiers.map(item => item.id);

                  this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.ms}], 'ent.nom', 'asc', 3000, 1)
                      .then(zone => {
                        zone.data.forEach(item => {
                          this.ms.push(item.id.toString())
                        })
                        this.listZones = zone.data.map(item => ({id: item.id, text: item.nom}));

                        this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.nom', 'asc', 3000, 1)
                            .then(secteurs => {
                              secteurs.data.forEach(item => {
                                this.ms.push(item.id)
                              })

                              this.refresh();

                            })
                      })
                })
          }
        })
      }else{
        await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 3000, 1)
            .then(response => {
              response.data.forEach(item => {
                this.listQuartiers.push({id: item.id, text: item.nom})
              })
              this.ms = this.listQuartiers.map(item => item.id);
              this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.ms}], 'ent.nom', 'asc', 3000, 1)
                  .then(zone => {
                    zone.data.forEach(item => {
                      this.ms.push(item.id.toString())
                    })
                    this.listZones = zone.data.map(item => ({id: item.id, text: item.nom}));

                    this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.nom', 'asc', 3000, 1)
                        .then(secteurs => {
                          secteurs.data.forEach(item => {
                            this.ms.push(item.id)
                          })

                          this.refresh();

                        })
                  })
            })
      }


    },
    changeQuartier(val) {
      this.selectedQuartier = val;
    },
    selectQuartier(val) {
      this.ms = [];
      this.isLoading=true;
      this.quartier = val;
      this.zone = null;
      this.ml = this.listQuartiers.find(item => item.id === Number(val.id))
      console.log(this.ml)

      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ms.push(val.id.toString())
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
              this.ms.push(item.id.toString());
            })

            this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.id', 'asc', 3000, 1)
                .then(secteurs => {
                  secteurs.data.forEach(item => {
                    this.ms.push(item.id.toString());
                  })

                  this.refresh()

                })
            //this.ms.push(this.listZones.map(item => item.id))

          })


    },
  },
  created() {

    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
    console.log(this.user)
    localStorage.setItem('selectedAnnee', moment().format("YYYY"))
    this.$store.dispatch("setAnnee", JSON.parse(localStorage.getItem('selectedAnnee')));
    this.selectedAnnee = this.$store.state.selectedAnnee;
    console.log(this.selectedAnnee)
    this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment(). format("YYYY-MM-DD");
    this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
    this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange.split('au')[1];


    if (this.user.groupe.nom === 'Super Admin'){
      this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            this.ids = [];
            this.ids = response.data.map(item => item.id);
            response.data.forEach(item => {
              this.listCollectivites.push({id: item.id, text: item.nom});
            })
            this.refresh();


          })

    }
    else{
      this.user.entites.forEach(item => {
        this.listCollectivites.push({id: item.id, text: item.nom});
        this.ids.push(item.id);
      })

    }

  },
  components: {
    Select2,
    Loading

  }

}
</script>
<style scoped>
@media print {
  .hidden-print {
    display: none !important;
  }
}

body {
  margin: 0;
  padding: 0px;
}

@media print {
  .print-container {
    display: none;
  }
}

.print-container {
  width: 100%;
  max-width: 210mm;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 297mm;
  box-sizing: border-box;
  padding: 20mm;
}

.page {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30mm;
  width: 100%;
  height: 100%;
  page-break-inside: avoid;
  grid-row-gap: 5px;
}

@media screen {
  .card {
    background-color: #f8f9fa;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
    width: 400px;
    height: 450px;
  }
}

@media screen {
  .card-image{
    width: 120px;
    height: 100px;
    flex: 1;
    padding: 10px;
  }
}

.card-content {
  padding: 10px;
}

@media screen {
  .qrcode {
    position: absolute;
    top: 60%;
    left: 35%;
  }
}

@media print {
  .print-container {
    display: block; /* Afficher le conteneur uniquement lors de l'impression */
    width: 100%;
    max-width: 210mm;
    font-family: Arial, sans-serif;
    box-sizing: border-box;
    padding: 20mm;
  }

  .page {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10mm;
    width: 100%;
    height: 100%;
    page-break-inside: avoid;
  }

  .card {
    background-color: #f8f9fa;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    width: 300px;
    height: 430px;
    page-break-inside: avoid;
  }

  .card-content {
    padding: 10px;
  }

  .qrcode {
    position: absolute;
    top: 60%;
    left: 28%;
  }

  .card-image{
    width: 100px;
    height: 100px;
    flex: 1;
    padding: 10px;
  }

}

</style>