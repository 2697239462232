<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0" v-if="selectedEntite != null">{{selectedEntite.nom}} > Tous les contribuables</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Impressions</router-link></li>
            <li class="breadcrumb-item active">Liste des transactions</li>
          </ol>
        </div>
        <div class="page-title-right">
          <div>
            <div style="display: inline">
              <button v-if="checkDate('day')"   @click="filterByDay" class="btn btn-primary ">
                Aujourd'hui
              </button>
              <button v-else   @click="filterByDay" class="btn btn-soft-secondary ">
                Aujourd'hui
              </button>
              <button  v-if="checkDate('week')"   @click="filterByWeek" class="btn btn-primary ">
                Cette semaine
              </button>
              <button  v-else   @click="filterByWeek" class="btn btn-soft-secondary ">
                Cette semaine
              </button>
              <button v-if="checkDate('month')"  @click="filterByMonth" class="btn btn-primary ">
                Ce mois
              </button>
              <button v-else  @click="filterByMonth" class="btn btn-soft-secondary ">
                Ce mois
              </button>
              <button v-if="checkDate('year')"  @click="filterByYear" class="btn btn-primary ">
                Cette année
              </button>
              <button v-else  @click="filterByYear" class="btn btn-soft-secondary ">
                Cette année
              </button>
              <div  class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical align-middle"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                  <li><button class="dropdown-item" @click="filterByYesterday">Hier</button></li>
                  <li><button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button></li>
                  <li><button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button></li>
                  <li><Flatpickr
                      @change="onDateChange"
                      v-model="daterange"
                      :config="config"
                      class="form-control "
                      placeholder="Select date"/></li>
                </ul>
              </div>
            </div>


          </div>

        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="row">
          <div class="col-md-6">
            <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
          </div>
          <div class="col-md-6">
            <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
          </div>

        </div>

        <div class="row mt-2">
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectedSecteur($event)" @change="changeSecteur($event)"  />
          </div>
        </div>
        <div class="row">
          <button @click="exportToPDF" class="btn btn-icon btn-success"><i class="ri-file-pdf-fill"></i> </button>
        </div>
        <div id="element-to-convert">

          <div class="row mt-3">
            <div class="col-md-12">
              <div class="card pricing-box">
                <div class="card-body bg-light m-2 p-4">
                  <div class="row">
                    <div class="col-md-4 text-start">
                      <img src="../../assets/images/logobko.png" style="width:80px; text-align: left" class="text-start"/>
                    </div>
                    <div class="col-md-8 text-start">
                      <h2 class="text-uppercase" style="text-align: left; font-size: 20px; font-weight: bold; vertical-align: central">Liste des transactions de <span v-if="entite != null"> {{entite.text}}</span> </h2>

                    </div>
                  </div>
                  <table class="table table-borderless text-start">
                    <thead>
                    <tr>
                      <th>Departement</th>
                      <th>Quartier</th>
                      <th>Zone</th>
                      <th>Secteur</th>
                      <th>Généré le</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><span v-if="departement != null">{{departement.text}}</span></td>
                      <td><span v-if="quartier != null">{{quartier.text}}</span></td>
                      <td><span v-if="secteur != null">{{secteur.text}}</span></td>
                      <td><span v-if="zone != null">{{zone.text}}</span></td>
                      <td>{{new Date().getDate() + '-' + new Date().getMonth()+1 + '-' + new Date().getFullYear() }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="card card-animate mt-3">

              <div class="card-body">

                <div class="row">
                  <div class="table-responsive">

                    <table class="table text-start mb-0 table-bordered">
                      <thead class="table-light">
                      <tr class="text-center" >
                        <th class="bg-success" scope="col" >#</th>
                        <th  class="bg-success" scope="col">Date </th>
                        <th  class="bg-success" scope="col" >Heure</th>

                        <th class="bg-success" scope="col" colspan="3">Contribuable</th>
                        <th class="bg-success" scope="col" colspan="4">Paiement</th>
                        <th class="bg-success" colspan="2" scope="col">Agent</th>
                        <th class="bg-success"></th>
                      </tr>
                      <tr>

                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Nom/Raison sociales  </th>
                        <th >N° KlisPay </th>

                        <th>Emplacement</th>
                        <th>Moyen de paiement</th>
                        <th>Reference</th>
                        <th>Montant</th>
                        <th>Statut</th>
                        <th>Pseudo</th>
                        <th>Nom</th>
                        <th></th>

                      </tr>
                      </thead>
                      <tbody v-if="listTransactions.length > 0">
                      <tr  v-for="(item, index) in listTransactions" :key="item.id" >
                        <td>{{ index+1 }}</td>
                        <td>{{ item.dateRequete.toString().slice(0,10) }}</td>
                        <td>{{ item.dateRequete.toString().slice(11,16) }}</td>
                        <td v-if="item.contrib">
                          {{item.contrib.fullName}}
                        </td>
                        <td v-else>
                        </td>

                        <td v-if="item.contrib != null">{{item.contrib.numKlis}}</td>
                        <td v-else></td>
                        <td v-if="item.contrib.ramification != null">{{item.contrib.ramification.nom}}</td>
                        <td v-else></td>
                        <td>{{item.moyenPaiement.nom}}</td>
                        <td>{{item.noTransaction}}</td>
                        <td>{{item.montant}}</td>
                        <td>{{item.statut}}</td>
                        <td v-if="item.bureauAccepteur != null">{{item.bureauAccepteur.username}}</td>
                        <td v-else></td>
                        <td v-if="item.bureauAccepteur != null">{{item.bureauAccepteur.fullName}}</td>
                        <td v-else></td>
                        <td>



                        </td>
                      </tr>

                      </tbody>
                      <tbody v-else>
                      <tr >
                        <td colspan="11">En chargement....</td>
                      </tr>

                      </tbody>
                      <tfoot class="table-light">
                      <tr class="text-center " >

                        <th class="bg-success" scope="col" >#</th>
                        <th class="bg-success" scope="col">Date</th>
                        <th class="bg-success" scope="col">Heure</th>
                        <th class="bg-success" scope="col" colspan="2">Contribuable</th>
                        <th class="bg-success" scope="col" colspan="4">Paiement</th>
                        <th class="bg-success" colspan="2" scope="col">Agent</th>
                        <th class="bg-success"></th>
                      </tr>



                      </tfoot>
                    </table>

                    <!-- end table -->
                  </div>
                </div>
                <!-- end table responsive -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Repository from '@/repository/TransactionRepository'
import html2pdf from "html2pdf.js"
import Select2 from 'vue3-select2-component';
import moment from "moment";

import store from "@/store";
import RamificationRepository from "@/repository/RamificationRepository";
import UserRepository from "@/repository/UserRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import Flatpickr from "vue-flatpickr-component";
import fr from "flatpickr/dist/l10n/fr";
export default {
  name: 'ImpressionlistContribuablePage',
  data() {
    return {
      listCollectivites: [],
      ids: [],
      listDepartements: [],
      listRamifications: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],
      dashboard: {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0,
        contribuableActuel: 0,
        prevuActuel: 0,
        paiementActuel:0
      },
      report: {},
      from: null,
      to: null,
      listUsers: [],
      tableau: [],
      entite: null,
      daterange: null,
      selectedAnnee: null,
      departement: null,
      quartier: null,
      zone:null,
      qt: [],
      secteur: null,
      isLoading: false,
      fullPage: true,
      user: null,
      repository: new Repository(),
      userRepo: new UserRepository(),
      orderBy: 'trans.dateTransaction',
      sortOrder: 'asc',
      itemsPerPage: 3000,
      selectedEntite: null,
      allSelected: false,
      selectedItems: [],
      ms: [],
      listTransactions: [],
      totalPages: 1,
      currentPage: 1,
      loading: false,
      ramificationRepo: new RamificationRepository(),
      entiteRepo: new EntiteRepository(),
      fields: [
      ]

    }
  },
  computed: {
    ramifications(){
      return this.listRamifications.map(item=>({id: item.id, text: item.nom}))
    },
    users(){
      return this.listUsers.map(item=>({id: item.id, text: item.fullName}))
    },
    collectivites(){
      return this.listCollectivites.map(item => ({id: item.id, text: item.text}));
    },
    config(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },
    dateConfig(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },

    pages() {
      let pages = Array.from({length: this.totalPages}, (_, i) => i + 1)
      let currentPage = this.currentPage
      let visiblePages = 5
      let start = currentPage - Math.floor(visiblePages / 2)
      let end = currentPage + Math.floor(visiblePages / 2)
      if (start < 1) {
        end += 1 - start
        start = 1
      }
      if (end > this.totalPages) {
        start -= end - this.totalPages
        end = this.totalPages
      }
      if (start > 1) {
        pages.splice(0, start - 1, '...')
      }
      if (end < this.totalPages) {
        pages.splice(end, this.totalPages - end, '...')
      }
      return pages.slice(start - 1, end)
    },

  },
  methods: {
    edit(enrollement){
      this.$router.push({name: 'editContribuables', params: { id: enrollement }  })
    },
    addFields() {
      this.fields.push({ colonne: '', operator: '', value: '', x: '', y: '' });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    formattedNumber(number) {
      if (number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return 0;
    },
    refresh(){

      this.repository.findOne("/v2/contribuables/enrollement/report?entite="+this.ids+"&ramification=&from="+this.from+"&to="+this.to)
          .then(response => {
            this.report = response;
          })
    },
    sortTable(colonne){
      this.orderBy = colonne;
      if (this.sortOrder === 'asc')
        this.sortOrder = 'desc';
      else
        this.sortOrder = 'asc'
      this.fillTable()

    },
    formatDate(value, format){
      return moment(value).format(format)
    },
    selectEntite(val){
      this.entite = val;
      this.isLoading=true;
      //  this.selectedEntite = val;

      this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null', value: ''}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom});
              this.fillTable();
            })
          });
    },
    changeEntite(val){
      this.entite = val;
      this.selectedEntite = val;
    },
    changeDepartement(val){
      this.selectedDepartement = val;
    },
    async selectDepartement(val){
      this.isLoading = true;
      this.departement = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})

            })
            this.fillTable();

          })
    },
    changeStatut(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeCollectivite(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeRamification(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeQuartier(val){
      this.selectedQuartier = val;
    },
    selectQuartier(val){
      this.isLoading=true;
      this.quartier = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
            })
            this.fillTable()
          })


    },
    contribuables()
    {
      return this.listContribuables.filter(item => item.statut='Validé');
    },


    exportToPDF() {

      html2pdf(document.getElementById("element-to-convert"), {
        margin:       [30, 0, 30, 0], //top, left, buttom, right
        filename:    'listetransaction.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { dpi: 192, scale: 2, letterRendering: true},
        jsPDF:        { unit: 'pt', format: 'a3', orientation: 'landscape'},
        pageBreak: { mode: 'css', after:'.break-page'}
      });
    },
    changeZone(val){
      this.selectedZone = val;
    },
    selectZone(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
            })
            this.fillTable()
          })

    },
    deleteNatureActivite(id){
      if(confirm("Etes-vous sûr de supprimer cet élément ?")){
        this.loading = true;
        this.repository.supprimer(id)
            .then(response => {
              this.fillTable();
              this.loading = false;
              console.log(response.status)
            })

      }


    },
    checkDate(type){
      if (type === 'day')
        return this.daterange === moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD") || this.daterange === moment().format("YYYY-MM-DD")
      if (type === 'week')
        return this.daterange === moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD")
      if (type === 'month')
        return this.daterange ===moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD")
      if (type === 'year')
        return this.daterange ===moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD")

    },

    bulkDelete(){
      if(confirm("Etes vous sûr de vouloir supprimer cet élément ?")){
        this.loading = true;

        this.selectedItems.forEach(element => {
          this.repository.supprimer(element);
          //console.log(element);
        })
        this.loading = false;
        this.fillTable();
      }

    },
    selectAll() {
      if (this.allSelected === false){
        this.allSelected = true;
        this.selectedItems = this.listContribuables.map(type => type.id);
        //  console.log(this.selectedItems);
      }
      else{
        this.allSelected = false;
        this.selectedItems = [];

        // console.log(this.selectedItems);

      }

      //console.log(this.allSelected)
    },
    goToPage(page){
      this.currentPage = page;
      this.$router.replace({ name: "listeContribuables",  query: {q1: "q1"} })
      this.fillTable();
    },

    handleItemPerPage(event){
      this.itemsPerPage = event;
      //   console.log('total de page ' + this.pages)
      // console.log(this.itemsPerPage)
      this.currentPage=1;
      this.$router.replace({ name: "listeContribuables",  query: {itemsPerPage: event, orderBy: this.orderBy} })

      this.fillTable();


    },
    onDateChange(){
      this.fillTable();
    },
    filterByDay() {
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
      this.fillTable();
      // console.log(this.daterange)
    },
    filterByYesterday() {
      this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
      this.fillTable();
      //console.log(this.daterange)
    },
    filterByLastWeek() {
      this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
      this.fillTable();
      //console.log(this.daterange)
    },
    filterByLastMonth() {
      this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      this.fillTable();
      //console.log(this.daterange)
    },
    filterByWeek() {
      this.daterange = moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD");
      // console.log(this.daterange);
      this.fillTable();
    },
    filterByMonth() {
      this.daterange = moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD");
      // console.log(this.daterange);
      this.fillTable();
    },
    filterByYear() {
      this.daterange = moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD");
      this.fillTable();
      console.log( ' au ' + moment().endOf('year'). format("YYYY-MM-DD"))
    },
    handleSearch(){
      this.fillTable();
    },
    handleOrderBy(event){
      this.$router.replace({ name: "listeContribuables",  query: {orderBy: event.target.value} })

      this.orderBy = event.target.value;
      this.fillTable();

      //console.log(this.orderBy)

    },
    handleSortOrder(event){
      this.sortOrder = event.target.value;
      // console.log(this.sortOrder)
      this.fillTable();

    },
    getDatesInRange(startDate, endDate) {
      const date = new Date(startDate.getTime());

      const dates = [];

      while (date <= endDate) {
        dates.push(moment(date).format('YYYY-MM-DD'));
        date.setDate(date.getDate() + 1);
      }

      return dates;
    },
    async fillTable(){
      this.isLoading = true;

      this.ms = [];

      // console.log(this.fields);

      this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'ent.id'), 1)
      this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'trans.dateTransaction'), 1)
      this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
      this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange;
      // this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y: this.to});


      //  var to = this.daterange.split('au')[1]
      //var dates = this.getDatesInRange(new Date(from), new Date(to))


      // this.ms = this.listZones.map(item => item.id);


      if (this.entite != null && this.departement === null) {
        this.fields.push({colonne: 'ent.id', operator: 'eq', value: this.entite.id});
        this.repository.findOne("/v2/contribuables/enrollement/report?entite="+this.entite.id+"&ramification=&from="+this.from+"&to="+this.to)
            .then(response => {
              this.report = response;
            })
      }
      if (this.departement != null && this.quartier === null){
        this.ms = this.listQuartiers.map(item => item.id);
        this.ms.push(this.departement.id)
        this.fields.push({colonne: 'ram.id', operator: 'in', value:  this.ms});
        this.repository.findOne("/v2/contribuables/enrollement/report?entite="+this.entite.id+"&ramification="+this.ms+"&from="+this.from+"&to="+this.to)
            .then(response => {
              this.report = response;
            })
      }
      if (this.quartier != null && this.zone === null){
        this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'ram.id'), 1)

        this.ms = this.listZones.map(item => item.id);
        this.ms.push(this.quartier.id)
        this.fields.push({colonne: 'ram.id', operator: 'in', value:  this.ms});
        this.repository.findOne("/v2/contribuables/enrollement/report?entite="+this.entite.id+"&ramification="+this.ms+"&from="+this.from+"&to="+this.to)
            .then(response => {
              this.report = response;
            })

      }
      if (this.zone != null && this.secteur === null){
        this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'ram.id'), 1)

        this.ms = this.listSecteurs.map(item => item.id);
        this.ms.push(this.zone.id)
        this.fields.push({colonne: 'ram.id', operator: 'in', value:  this.ms});
        //this.fields = [{colonne: 'ram.id', operator: 'in', value:  this.listSecteurs.map(item => item.id)}];
        this.repository.findOne("/v2/contribuables/enrollement/report?entite="+this.entite.id+"&ramification="+this.ms+"&from="+this.from+"&to="+this.to)
            .then(response => {
              this.report = response;
            })
      }
      if (this.secteur != null ){
        this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'ram.id'), 1)

        this.fields.push({colonne: 'ram.id', operator: 'eq', value:  this.secteur});
        this.repository.findOne("/v2/contribuables/enrollement/report?entite="+this.entite.id+"&ramification="+this.secteur.id+"&from="+this.from+"&to="+this.to)
            .then(response => {
              this.report = response;
            })
      }
      this.fields.push({colonne: 'trans.dateTransaction', operator: 'dateBetween', x: this.from, y: this.to});

      // console.log(this.fields)
      await this.repository.search(this.fields, this.orderBy, this.sortOrder, this.itemsPerPage, this.currentPage)
          .then(response => {

            this.listTransactions = response.data;

            this.totalPages = Math.ceil(response.meta.total_records / this.itemsPerPage);

            this.isLoading = false;

          });



    },


  },
  created() {

    this.selectedEntite = store.state.selectedCollectivite;
    //this.fields.push({colonne: 'contrib.statut', operator: 'eq', value: 'Validé'})
    this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment(). format("YYYY-MM-DD");
    this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
    this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange.split('au')[1];

    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
    this.userRepo.search([{colonne: '', operator: '', value: ''}], 'user.username', 'asc', 3000, 1)
        .then(response => {
          this.listUsers = response.data;
          //     this.fillTable();
        })

    this.ramificationRepo.search([{colonne: 'ram.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 3000, 1)
        .then(response => {
          this.listRamifications = response.data;
          //     this.fillTable();
        })

    this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.ids = [];
          this.ids = response.data.map(item => item.id);
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom});

          })


        })

  },
  components:{
    Loading,
    Select2,
    Flatpickr,



  }
}
</script>
