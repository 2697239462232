
import ListeTypeUser from '@/components/Type_user/ListeTypeUser';
import CreateTypeUser from '@/components/Type_user/CreateTypeUser';
import EditTypeUser from '@/components/Type_user/EditTypeUser';
export default [
    {
        path: '/type_users',
        name: 'listTypeUser',
        component: ListeTypeUser
    },
    {
        path: '/type_users/create',
        name: 'createTypeUser',
        component: CreateTypeUser
    },
    {
        path: '/type_users/:id',
        name: 'editTypeUser',
        component: EditTypeUser
    }
]