import ListeTypeRamification from '@/components/TypeRamification/ListeTypeRamification';
import CreateTypeSousEntite from '@/components/TypeRamification/CreateTypeRamification'
import EditTypeRamification from "@/components/TypeRamification/EditTypeRamification.vue";
export default [
    {
        path: '/type_sous_entites',
        name: 'ListeTypeSousEntite',
        component: ListeTypeRamification
    },
    {
        path: '/type_sous_entites/create',
        name: 'createTypeSousEntite',
        component: CreateTypeSousEntite
    },

    {
        path: '/type_sous_entites/edit/:id',
        name: 'editTypeSousEntite',
        component: EditTypeRamification
    }
]