<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          <span v-if="entite !=null">{{ entite.text }}</span>
          <span v-if="departement !=null"> > {{ departement.text }}</span>
          <span v-if="quartier !=null"> > {{ quartier.text }}</span>
          <span v-if="zone !=null"> > {{ zone.text }}</span>
        </h4>


      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
    </div>
  </div>


  <div class="row mt-2">
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectedSecteur($event)" @change="changeSecteur($event)"  />
    </div>
    <div class="col-md-4">
      <div class="input-group">
        <Flatpickr
            @change="onDateChange"
            v-model="daterange"
            :config="config"
            class="form-control "
            placeholder="Select date"/>
        <div class="input-group-text bg-primary border-primary text-white">
          <i class="ri-calendar-2-line"></i>
        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <button @click="exportToPDF" class="btn btn-icon btn-success"><i class="ri-file-pdf-fill"></i> </button>
  </div>
  <div id="element-to-convert" >
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="card pricing-box">
          <div class="card-body bg-light m-2 p-4">
            <h2 class="text-uppercase">Situations des recouvrements <span v-if="entite != null"> {{entite.text}}</span> </h2>
            <table class="table table-borderless text-start">
              <thead>
              <tr>
                <th>Departement</th>
                <th>Quartier</th>
                <th>Zone</th>
                <th>Secteur</th>
                <th>Généré le</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><span v-if="departement != null">{{departement.text}}</span></td>
                <td><span v-if="quartier != null">{{quartier.text}}</span></td>
                <td><span v-if="secteur != null">{{secteur.text}}</span></td>
                <td><span v-if="zone != null">{{zone.text}}</span></td>
                <td>{{new Date().getDate() + '-' + new Date().getMonth()+1 + '-' + new Date().getFullYear() }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <table class="table text-start mb-0 table-bordered">
        <thead class="table-light">
        <tr class="text-center" >

          <th class="bg-success" scope="col" >#</th>
          <th class="bg-success" scope="col">Date</th>
          <th class="bg-success" scope="col">Heure</th>
          <th class="bg-success" scope="col" colspan="4">Contribuable</th>
          <th class="bg-success" scope="col" >Base taxable</th>
          <th class="bg-success" colspan="3" scope="col">Situations fiscale</th>
          <th class="bg-success" colspan="2" scope="col">Agent</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th>N° KlisPay</th>
          <th>Nom/Raison sociale</th>
          <th>Emplacement</th>
          <th>Statut</th>
          <th></th>
          <th>Prevu</th>
          <th>Payer</th>
          <th>Restant</th>
          <th>Pseudo</th>
          <th>Nom</th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in listPayeur" :key="item.id">
          <td>{{ index+1 }}</td>
          <td>{{ item.dateTransaction.toString().slice(0,10) }}</td>
          <td>{{ item.dateTransaction.toString().slice(11,17) }}</td>
          <td><router-link :to="{name: 'ficheContribuables', params: { id: item.id }}"> {{item.numKlis}}</router-link></td>
          <td>{{item.fullName}}</td>
          <td>{{item.ramification.nom}}</td>
          <td>
            <span v-if="item.enabled===true" class="badge bg-success">Confirmé</span>
            <span v-else class="badge bg-danger">En attente de confirmation</span>
          </td>
          <td>
            <span v-html="item.baseTaxables"></span>

          </td>

          <td>{{ formattedNumber(item.situation.total) }}</td>
          <td>{{ formattedNumber(item.situation.payer) }}</td>
          <td>{{ formattedNumber(item.situation.restant) }}</td>
          <td>{{ item.createdBy.username }}</td>
          <td>{{ item.createdBy.fullName }}</td>
          <td></td>
          <td></td>
        </tr>

        </tbody>
        <tfoot class="table-light">
        <tr class="text-center" >

          <th class="bg-success" scope="col" colspan="8" >TOTAL</th>

          <th class="bg-success">{{totalPrevu}}</th>
          <th class="bg-success">{{totalPayer}}</th>
          <th class="bg-success">{{totalRestant}}</th>
          <th class="bg-success"></th>
          <th class="bg-success"></th>
        </tr>
        </tfoot>
      </table>

    </div>

  </div>

</template>

<script>
import html2pdf from "html2pdf.js"

import Select2 from 'vue3-select2-component';
import Flatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import fr from "flatpickr/dist/l10n/fr";
import RamificationRepository from "@/repository/RamificationRepository";
import DashboardRepository from "@/repository/DashboardRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import Repository from '@/repository/ContribuableRepository'

export default {
  name: 'ImpressionListeContribuablePage',
  computed: {
    listPayeur(){
      if(this.listContribuables != null)
        return this.listContribuables.filter(item => parseInt(item.situation.payer) > 0)
      else
        return null;

    },
    totalPrevu(){
      let sum = 0;

      if (this.listPayeur != null)
        for (const item of this.listPayeur) {
        sum += (item != null) ? parseInt(item.situation.total) : 0;
      }
      return this.formattedNumber(sum);
    },
    totalPayer(){
      let sum = 0;
      if (this.listPayeur != null)
        for (const item of this.listPayeur) {
        sum += (item != null) ? parseInt(item.situation.payer) : 0;
      }
      return this.formattedNumber(sum);
    },
    totalRestant(){
      let sum = 0;
      if (this.listPayeur != null)
        for (const item of this.listPayeur) {
          sum += (item != null) ? parseInt(item.situation.restant) : 0;
        }
      return this.formattedNumber(sum);
    },
  },
  data(){
    return{
      listContribuables: null,
      repository: new Repository(),
      entite: null,
      departement: null,
      quartier: null,
      zone:null,
      secteur: null,
      selectedEntite: null,
      daterange: null,
      selectedDepartement: null,
      selectedQuartier: null,
      selectedZone: null,
      selectedSecteur: null,
      ramificationRepo: new RamificationRepository(),
      dashboardRepo: new DashboardRepository(),
      entiteRepo: new EntiteRepository(),
      myValue: '',
      listCollectivites: [],
      listDepartements: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],
      config: {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        // defaultDate:[new Date(), new Date().setDate(new Date().getDate() + 7)]
      }
    }
  },
  methods:{
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        filename: "liste-des-contribuables.pdf",
        jsPDF:        { unit: 'mm', format: 'a4', orientation: 'landscape' }
      });
    },
    async fillTable(id){
      try {

        //console.log(this.$route.query.departement);

        if (this.selectedDepartement != null && this.selectedQuartier === null)
          this.fields= [
            { colonne: 'p.id', operator: 'eq', value: id }
          ]
        if (this.selectedQuartier != null  && this.selectedZone === null){
          this.fields= [
            { colonne: 'ram.id', operator: 'eq', value: id }
          ]
        }


          if (this.selectedZone != null && this.selectedSecteur === null){
            this.fields= [
              { colonne: 'ram.id', operator: 'eq', value: id }
            ]
            //console.log(this.fields)

          }


        const trResponse = await this.repository.search(this.fields, 'contrib.nom', 'asc', 5000, 1);
        //console.log(trResponse)
        this.listContribuables = trResponse.data;
        //console.log(this.listContribuables);
        this.totalPages = Math.ceil(trResponse.meta.total_records / this.itemsPerPage);

      }catch (e) {
        console.log(e);
      }
    },

    formattedNumber(number) {
      if (number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return 0;
    },
    changeEntite(val){
      this.selectedEntite = val;
      console.log(val)
    },
    async selectEntite(val){

      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.entite = val;


      this.listDepartements = [];
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null', value: ''}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom})

            })
            this.fillTable();
          });

    },
    changeDepartement(val){
      this.selectedDepartement = val;


    },
    async selectDepartement(val){
      this.departement = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
     // console.log(val.id)
     // this.fillTable(val.id);

      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})

            })
            this.fillTable();
          })

    },
    changeQuartier(val){
      this.selectedQuartier = val;
    //  this.fillTable(val.id);

    },
    selectQuartier(val){
      this.quartier = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.fillTable(val.id);

      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})

            })
            this.fillTable();
          })

    },
    changeZone(val){
      this.selectedZone = val;
      //this.fillTable(val.id);

    },
    selectZone(val){
      this.zone = val;

      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listSecteurs = [];
    //  this.fillTable(val.id);

      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})

            })
            this.fillTable();
          })

    },
  },
  created() {
    this.entiteRepo.search([{colonne: '', operator: '', value: ''}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom})

          })
        })
  },
  components:{
    Select2,
    Flatpickr
  }
}
</script>