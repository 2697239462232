<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Ville</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Configurations</router-link></li>
            <li class="breadcrumb-item active">Ville</li>
          </ol>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <form @submit.prevent="createVille">
          <div class="card card-animate">

            <div class="card-body">
              <div class="row text-start">
                <div class="col-sm-12">
                  <label for="firstName" class="form-label">Nom</label>
                  <input v-model="ville.nom" type="text" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                </div>
              </div>
              <div class="row text-start mt-3">
                <div class="col-sm-12">
                  <label for="firstName" class="form-label">Pays</label>
                  <select v-model="ville.pays" type="text" class="form-control" required>
                    <option :key="item.id" :value="item.id" v-for="item in listPays">{{item.nom}}</option>
                  </select>
                  <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <div class="form-check form-switch form-switch-success">
                    <input  v-model="ville.enabled" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" >
                    Active ?
                  </div>
                </div>
              </div>

            </div>
            <div class="card-footer">
              <div class="row">
                <button type="submit" class="btn btn-primary">Enregistrer</button>

              </div>
            </div>
          </div>

        </form>

      </div>
    </div>
  </div>
</template>

<script>
import Repository from '@/repository/VilleRepository'
import PaysRepository from '@/repository/PaysRepository'

export default {
  name: 'createVillePage',
  data() {
    return {
      ville: {},
      repository: new Repository(),
      paysRepo: new PaysRepository(),
      listPays: [],
      listCategorieActivite: null


    }
  },
  computed: {
  },
  methods: {
    createVille(){
      this.repository.edit(this.ville, this.$route.params.id);
      this.$router.push({ name: 'listeVilles' })
    }

  },
  created() {
      this.repository.find(this.$route.params.id)
          .then(response => {
              this.ville = response;
              this.ville.pays = this.ville.pays.id;

          })
    this.paysRepo.search([{ colonne: 'p.enabled', operator: 'eq', value: 1 }], 'p.nom', 'asc', 30, 1)
        .then(response => {
          this.listPays = response.data;
          //   console.log(this.listCollectivite);
        })
        .catch(error => {
          console.log(error);
        });

  }
}
</script>