<template>
  <div ref="myLayout" class="app-menu navbar-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <!-- Dark Logo-->
      <a href="index.html" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="../assets/images/logo-colored.svg" alt="" height="22">
                    </span>
        <span class="logo-lg">
                        <img src="../assets/images/logo-colored.svg" alt="" height="17">
                    </span>
      </a>
      <!-- Light Logo-->
      <a href="index.html" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="../assets/images/logo-sm.png" alt="" height="50" style="height: 50px">
                    </span>
        <span class="logo-lg">
                        <img src="../assets/images/logo-light.png" alt="" height="50" style="height: 50px">
                    </span>
      </a>
      <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
              id="vertical-hover">
        <i class="ri-record-circle-line"></i>
      </button>
    </div>

    <div id="scrollbar">
      <div class="container-fluid">

        <div id="two-column-menu">
        </div>
        <ul class="navbar-nav" id="navbar-nav">
          <li class="menu-title"><span data-key="t-menu">Menu</span></li>
          <li class="nav-item">
            <router-link :to="{path: '/'}" class="nav-link" data-key="t-analytics">
              <i class="ri-dashboard-2-line"></i> <span data-key="t-dashboards">Tableau de bord</span></router-link>
          </li>
          <li class="nav-item">
            <a v-if="this.selectedCollectivite != null" class="nav-link menu-link" href="#sidebarCollectivite"
               data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
              <i class="ri-home-2-line "></i> <span data-key="t-apps">{{ this.selectedCollectivite.nom }}</span>
            </a>
            <a v-else-if="this.selectedRamification != null" class="nav-link menu-link" href="#sidebarApps"
               data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
              <i class="ri-home-2-line "></i> <span data-key="t-collectivite">{{ this.selectedRamification.nom }}</span>
            </a>
            <a v-else class="nav-link menu-link" href="#sidebarCollectivite" data-bs-toggle="collapse" role="button"
               aria-expanded="false" aria-controls="sidebarApps">
              <i class="ri-home-2-line "></i> <span data-key="t-collectivite">Collectivité</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarCollectivite">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link :to="{name: 'listeContribuables'}" class="nav-link" data-key="t-calendar">
                    Contribuables
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link :to="{name: 'listeEquipements'}" class="nav-link" data-key="t-calendar"> Equipements
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link :to="{name: 'listeTransactions'}" class="nav-link" data-key="t-chat"> Transactions
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link :to="{name: 'collecte'}" class="nav-link" data-key="t-chat"> Collecte</router-link>
                </li>

                <li class="nav-item">
                  <router-link :to="{name: 'carte'}" class="nav-link" data-key="t-chat"> Carte</router-link>
                </li>

                <li class="nav-item" @click="hideMenu"
                    v-if="user.groupe.nom === 'Admin NTA' || user.groupe.nom === 'Super Admin'">
                  <a href="#sidebarEmail" class="nav-link" data-bs-toggle="collapse" role="button" aria-expanded="false"
                     aria-controls="sidebarEmail" data-key="t-email">
                    Configurations
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarEmail">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link :to="{name: 'listRamifications'}" class="nav-link"> Sous-entités</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link :to="{name: 'listeTypeEquipement'}" class="nav-link"> Type équipement</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link :to="{name: 'listeCategorieActivite'}" class="nav-link"> Catégorie activité
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link :to="{name: 'listNatureActivite'}" class="nav-link"> Nature activité</router-link>
                      </li>
                      <li class="nav-item">
                        <a href="#sidebaremailTemplates" class="nav-link" data-bs-toggle="collapse" role="button"
                           aria-expanded="false" aria-controls="sidebaremailTemplates" data-key="t-email-templates">
                          Taxe
                        </a>
                        <div class="collapse menu-dropdown" id="sidebaremailTemplates">
                          <ul class="nav nav-sm flex-column">
                            <li class="nav-item">
                              <router-link :to="{name: 'listCategorieTaxes'}" class="nav-link"
                                           data-key="t-basic-action"> Catégories de taxes
                              </router-link>
                            </li>
                            <li class="nav-item">
                              <router-link :to="{name: 'listTaxes'}" class="nav-link"> Bases taxables</router-link>
                            </li>

                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a href="#sidebarAdministration" class="nav-link" data-bs-toggle="collapse" role="button"
                     aria-expanded="false" aria-controls="sidebarEmail" data-key="t-email">
                    Administration
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarAdministration">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link :to="{name: 'listAgent'}" class="nav-link"> Agents</router-link>
                      </li>

                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>


          <li class="nav-item" @click="hideMenu"
              v-if="user.groupe.nom === 'Admin NTA' || user.groupe.nom === 'Super Admin'">
            <a class="nav-link menu-link" href="#sidebarReleve" data-bs-toggle="collapse" role="button"
               aria-expanded="false" aria-controls="sidebarTables">
              <i class="ri-printer-line"></i> <span data-key="t-tables">Relevés</span>
            </a>
            <div class="collapse menu-dropdown mega-dropdown-menu" id="sidebarReleve">
              <div class="row">
                <div class="col-lg-6">
                  <ul class="nav nav-sm flex-column">
                    <li>
                      <router-link :to="{name: 'releve-liste-equipement'}" class="nav-link" data-key="t-alerts"> Liste
                        des équipements
                      </router-link>
                    </li>

                    <li>
                      <router-link :to="{name: 'releve-equipements-payes'}" class="nav-link" data-key="t-alerts">
                        Liste des payés
                      </router-link>
                    </li>

                    <li>
                      <router-link :to="{name: 'releve-equipements-impayes'}" class="nav-link" data-key="t-alerts">Liste
                        des impayés
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="{name: 'releve-facture'}" class="nav-link" data-key="t-alerts">
                        Facture
                      </router-link>
                    </li>
                    <!--                    <li class="nav-item">
                                          <router-link :to="{name: ''}"  class="nav-link" data-key="t-buttons">Par base taxable</router-link>
                                        </li>
                                        <li class="nav-item">
                                          <router-link :to="{name: 'impayes'}"  class="nav-link" data-key="t-alerts">Impayés</router-link>
                                        </li>

                                        <li class="nav-item">
                                          <router-link :to="{name: 'moyen-paiement'}"  class="nav-link" data-key="t-buttons">Moyens de paiement</router-link>
                                        </li>-->
                    <!--                    <li class="nav-item">
                                          <router-link :to="{name: 'releveTdrl'}"  class="nav-link" data-key="t-buttons">TDRL</router-link>
                                        </li>-->


                  </ul>
                </div>

              </div>
            </div>

          </li>

          <li class="nav-item" @click="hideMenu"
              v-if="user.groupe.nom === 'Admin NTA' || user.groupe.nom === 'Super Admin'">
            <a class="nav-link menu-link" href="#sidebarStatistiques" data-bs-toggle="collapse" role="button"
               aria-expanded="false" aria-controls="sidebarApps">
              <i class="ri-file-chart-2-line"></i> <span data-key="t-apps">Statistiques</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarStatistiques">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <a href="#sidebarRecensement" class="nav-link" data-bs-toggle="collapse" role="button"
                     aria-expanded="false" aria-controls="sidebarEmail" data-key="t-email">
                    Recensement
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarRecensement">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link :to="{name: 'recensementSousEntite'}" class="nav-link">Par sous-entités
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link :to="{name: 'recensementAgent'}" class="nav-link">Par agent</router-link>
                      </li>
                      <li class="nav-item" @click="hideMenu"
                          v-if="user.groupe.nom === 'Admin NTA' || user.groupe.nom === 'Super Admin'">
                        <router-link :to="{name: 'recensementActivite'}" class="nav-link">Par Base taxable</router-link>
                      </li>

                    </ul>
                  </div>
                </li>
                <li class="nav-item" @click="hideMenu"
                    v-if="user.groupe.nom === 'Admin NTA' || user.groupe.nom === 'Super Admin'">
                  <a href="#sidebarRecouvrement" class="nav-link" data-bs-toggle="collapse" role="button"
                     aria-expanded="false" aria-controls="sidebarEmail" data-key="t-email">
                    Recouvrement
                  </a>
                  <div class="collapse menu-dropdown" id="sidebarRecouvrement">
                    <ul class="nav nav-sm flex-column">
                      <li class="nav-item">
                        <router-link :to="{name: 'recouvrementParMoyenPaiement'}" class="nav-link">Par Moyen de
                          paiement
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link :to="{name: 'recouvrementParSousEntite'}" class="nav-link">Par sous-entités
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link :to="{name: 'recouvrementParAgent'}" class="nav-link">Par agent</router-link>
                      </li>
                      <!--                      <li class="nav-item">
                                              <router-link :to="{name: 'recouvrementParActivite'}" class="nav-link" >Par base taxable </router-link>
                                            </li>-->

                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>


          <li v-if="user.groupe.nom === 'Super Admin'" class="nav-item">
            <a class="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button"
               aria-expanded="false" aria-controls="sidebarApps">
              <i class="ri-file-chart-2-line"></i> <span data-key="t-apps">Administration</span>
            </a>
            <div class="collapse menu-dropdown mega-dropdown-menu" id="sidebarApps">
              <div class="row">
                <div class="col-md-6">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link :to="{name: 'listeEntite'}" class="nav-link">Collectivité</router-link>
                    </li>
                    <!--                      <li class="nav-item">
                                              <router-link :to="{name: 'listeAutreOrganisation'}"  class="nav-link">Autres organisation</router-link>
                                          </li>
                                        <li class="nav-item"><router-link  :to="{name: 'listCategorieTaxes'}" class="nav-link">Categorie de taxes</router-link></li>-->


                  </ul>
                </div>
                <div class="col-md-6">
                  <ul class="nav nav-sm flex-column">

                    <li class="nav-item">
                      <router-link :to="{name: 'ListeTypeSousEntite'}" class="nav-link">Type sous-entité</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{name: 'pays'}" class="nav-link">Pays</router-link>
                    </li>


                    <li class="nav-item">
                      <router-link :to="{name: 'listeVilles'}" class="nav-link">Villes</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{name: 'listeMoyenPaiement'}" class="nav-link">Moyen de paiement</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{name: 'listeFrequence'}" class="nav-link">Fréquences</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{name: 'listTypeUser'}" class="nav-link">Type utilisateur</router-link>
                    </li>

                    <li class="nav-item">
                      <router-link :to="{ name: 'listRoles' }" class="nav-link">Role utilisateur</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{ name: 'listUsers' }" class="nav-link">Utilisateur</router-link>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </li>


        </ul>
      </div>
      <!-- Sidebar -->
    </div>

    <div class="sidebar-background"></div>
  </div>
</template>
<script>
export default {
  name: 'AppMenu',
  data() {
    return {
      user: null,
      userPermissions: null,
      selectedRamification: null,
      selectedCollectivite: null,
      navbarMenuHTML: null,
      horizontalMenuSplit: 7,
      hideMenuCol: true
    }
  },
  methods: {
    hideMenu() {
      this.hideMenuCol = false;
    }


  },
  created() {
    try {
      this.$store.dispatch("setCollectivite", JSON.parse(localStorage.getItem('selectedCollectivite')));
      this.selectedCollectivite = this.$store.state.selectedCollectivite;

      this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
      this.user = this.$store.state.user;
      this.$store.dispatch("setUserPermissions", JSON.parse(localStorage.getItem('userPermissions')));
      this.userPermissions = this.$store.state.userPermissions;
      this.$store.dispatch("setRamification", JSON.parse(localStorage.getItem('selectedRamification')));
      this.selectedRamification = this.$store.state.selectedRamification;

      //    console.log(this.userPermissions[0].id)

      // this.selectedCollectivite = this.$store.state.selectedCollectivite;
      // console.log(JSON.parse(localStorage.getItem('setUserPermissions')))

    } catch (e) {
      console.log(e)

    }
  },
  mounted() {


    //  this.init();

    //this.initLeftMenuCollapse();
    //this.toggleHamburgerMenu();
  }
}
</script>