import API from '@/helpers/Api';


export default class TypeUser {
    id;
    nom;
    description;
    enabled;
    createdAt;
    updated;
    api= new API();
    constructor() {
        this.id= null;
        this.nom = null;
        this.description = null;
        this.enabled = false;
    }
}