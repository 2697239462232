<style>
.container1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid black;
}

h4 {
  text-align: center;
}
</style>
<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Agent</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home' }">Collectivité</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home' }">Administration</router-link>
            </li>
            <li class="breadcrumb-item active">Agents</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <div class="d-print-none">
    <button @click="printPage" style="width: 200px">Imprimer</button>
  </div>
  <div>
    <div class="row" v-if="agent != null">
      <div class="col-lg-12">
        <div>
          <div class="card text-start">
            <!-- Tab panes -->
            <div class="container1">
              <img src="../../assets/images/logobko.png" style="width:80px; text-align: left" class="text-start"/>
              <h1 style="text-align: center;">
                <u>Fiche individuelle de l'agent</u></h1>
              <img class="rounded-circle header-profile-user"
                   src="../../assets/images/logo_klis.svg"
                   style="float: right; width: 180px;height: 200px;" alt="Header Avatar">
            </div>
            <div class="table-responsive">
              <div class="card-header" style="border: 2px solid black;">
                <h4 class="card-title mb-0  me-2">Informations personnelles</h4>

              </div>
              <table class="table table-borderless mb-0" style="border: 2px solid black;">
                <tbody>
                <tr>
                  <th class="ps-0" scope="row" style="border: 2px solid black; width: 200px;"><p
                      style="padding-left: 10px">
                    Identifiant de l'agent :</p></th>
                  <td class="text-muted" style="border: 2px solid black;padding-left: 20px;width: 100px;
  height: 65px;"><p
                      style="text-align: left">{{ agent.id }}</p></td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row" style="border: 2px solid black; width: 200px;"><p
                      style="padding-left: 10px">
                    Nom:</p></th>
                  <td class="text-muted" style="border: 2px solid black;width: 100px;
  height: 65px;"><p style="text-align: left">
                    {{ agent.nom }}</p></td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row" style="border: 2px solid black; width: 200px;"><p
                      style="padding-left: 10px">
                    Prénom :</p></th>
                  <td class="text-muted" style="border: 2px solid black;width: 100px;
  height: 65px;"><p style="text-align: left">
                    {{ agent.prenom }}</p></td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row" style="border: 2px solid black; width: 200px;"><p
                      style="padding-left: 10px">
                    Nom d'utilisateur :</p></th>
                  <td class="text-muted" style="border: 2px solid black;width: 100px;
  height: 65px;"><p style="text-align: left">
                    {{ agent.username }}</p></td>
                </tr>
                <tr>
                  <th class="ps-0" scope="row" style="border: 2px solid black; width: 200px;"><p
                      style="padding-left: 10px">
                    E-mail :</p></th>
                  <td class="text-muted" style="border: 2px solid black; width: 100px;
  height: 65px;"><p style="text-align: left">
                    {{ agent.email }}</p></td>
                </tr>
                </tbody>
              </table>
            </div>


            <!--end row-->
            <div class="card text-start">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="table-responsive">
                      <div>
                        <h4 class="card-title flex-grow-1 mb-0" style="border: 2px solid black;"><p
                            style="padding-left: 15px">Lieu d'affectation</p></h4>
                      </div>
                      <table class="table" style="border: 2px solid black;">
                        <thead>
                        <th style="border: 2px solid black; width: 200px;">Commune</th>
                        <th style="border: 2px solid black; width: 200px;">Adresse</th>
                        <th style="border: 2px solid black; width: 200px;">Code commune</th>
                        </thead>
                        <tbody>
                        <tr v-for="ag in agent.entites" :key="ag">
                          <td style="border: 2px solid black; width: 100px;
  height: 65px;">{{ ag.nom }}
                          </td>
                          <td style="border: 2px solid black; width: 100px;
  height: 65px;">{{ ag.adresse }}
                          </td>
                          <td style="border: 2px solid black; width: 100px;
  height: 65px;">{{ ag.code }}
                          </td>
                        </tr>
                        </tbody>
                        <thead>
                        <th style="border: 2px solid black; width: 200px;">Département</th>
                        <th style="border: 2px solid black; width: 200px;">Quartier</th>
                        <th style="border: 2px solid black; width: 200px;">Code quartier</th>
                        </thead>
                        <tbody>
                        <tr v-for="ag in agent.ramifications" :key="ag">
                          <td style="border: 2px solid black; width: 100px;
  height: 65px;">{{ ag.parent.nom }}
                          </td>
                          <td style="border: 2px solid black; width: 100px;
  height: 65px;">{{ ag.nom }}
                          </td>
                          <td style="border: 2px solid black; width: 100px;
  height: 65px;">{{ ag.code }}
                          </td>
                        </tr>

                        </tbody>
                        <thead>
                        <th colspan="3" style="text-align:center;font-size: 15px; width: 200px;">Situations</th>
                        </thead>
                        <tbody>
                        <tr>
                          <td style="border: 2px solid black; width: 100px;
  height: 65px;">
                            <div class="py-4 px-3 text-start" style="border: 2px solid black;">
                              <h5 class="text-muted text-uppercase fs-13">Nombre enrollements
                              </h5>
                              <div class="d-flex align-items-center">
                                <div class="flex-grow-1 ms-3">
                                  <h2 class="mb-0"><span class="counter-value"
                                                         :data-target="agent.situations.nbreEnrollements"></span>{{
                                      agent.situations.nbreEnrollements
                                    }}</h2>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td style="border: 2px solid black; width: 100px;
  height: 65px;">
                            <div class="py-4 px-3" style="border: 2px solid black;">
                              <h5 class="text-muted text-uppercase fs-13">Nombre transactions
                              </h5>
                              <div class="d-flex align-items-center">
                                <div class="flex-grow-1 ms-3">
                                  <h2 class="mb-0"><span class="counter-value"
                                                         :data-target="agent.situations.nbreTransactions"></span>{{
                                      agent.situations.nbreTransactions
                                    }}</h2>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td style="border: 2px solid black; width: 100px;
  height: 65px;">
                            <div class="py-4 px-3" style="border: 2px solid black;">
                              <h5 class="text-muted text-uppercase fs-13">Montant transactions
                              </h5>
                              <div class="d-flex align-items-center">
                                <div class="flex-grow-1 ms-3">
                                  <h2 class="mb-0"><span class="counter-value"
                                                         :data-target="agent.situations.totalTransactions"></span>{{
                                      agent.situations.totalTransactions.toLocaleString('fr-FR')
                                    }}</h2>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>


                      </table>


                    </div>
                  </div>

                </div><!-- end row -->


              </div><!-- end card body -->
            </div><!-- end card -->


            <!--end tab-pane-->
          </div>
          <!--end tab-content-->
        </div>
      </div>
      <!--end col-->
    </div>
  </div>
</template>

<script>
import Repository from '@/repository/AgentRepository'

export default {
  name: 'ficheAgent',
  data() {
    return {
      divColor: 'red',
      printMode: false,
      repository: new Repository(),
      agent: null,
      center: {lat: 51.093048, lng: 6.842120},
      markers: [
        {
          position: {
            lat: 51.093048, lng: 6.842120
          },
        }
        , // Along list of clusters
      ]
    }
  },
  methods: {
    printPage() {
      window.print();
    },
    printDate(dte) {
      var dt = new Date(dte);
      return dt.getDay() + '-' + dt.getMonth() + '-' + dt.getFullYear();
    }

  },
  created() {
    this.repository.find(this.$route.params.id)
        .then(response => {
          this.agent = response;
          console.log(this.agent)
        })
        .catch(error => {
          console.log(error)
        })
  }
}
</script>