import { createRouter, createWebHistory } from 'vue-router'
import auth from "@/router/auth";
import dashboard from "@/router/dashboard";
import Layout from "@/components/Layout";
import users from "@/router/users";
import role from "@/router/role";
import ramification from "@/router/ramification";
import categorieTaxe from "@/router/categorieTaxe";
import taxe from "@/router/taxe";
import typeEquipement from "@/router/typeEquipement";
import categorieActivite from "@/router/categorieActivite";
import natureActivite from "@/router/natureActivite";
import contribuable from "@/router/contribuable";
import transaction from "@/router/transaction";
import impression from "@/router/impression";
import entite from "@/router/entite";
import agent from "@/router/agent";
import equipement from "@/router/equipement";
import statistique from "@/router/statistique";
import releve from "@/router/releve";
import ville from "@/router/ville";
import autreOrganisation from "@/router/autreOrganisation";
import moyenPaiement from "@/router/moyenPaiement";
import pays from "@/router/pays";
import typeUser from "@/router/typeUser";
import typeRamification from "@/router/typeRamification";
import frequence from "@/router/frequence";
import carte from "@/router/carte";
import collecte from "@/router/collecte";


const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    children: [
        ...dashboard,
        ...users,
        ...role,
        ...ramification,
        ...categorieTaxe,
        ...taxe,
        ...typeEquipement,
        ...categorieActivite,
        ...natureActivite,
        ...contribuable,
        ...equipement,
        ...transaction,
        ...impression,
        ...releve,
        ...entite,
        ...agent,
        ...statistique,
        ...ville,
        ...autreOrganisation,
        ...moyenPaiement,
        ...pays,
        ...typeUser,
        ...typeRamification,
        ...frequence,
        ...carte,
        ...collecte

    ],
    beforeEnter: (to, from, next) => {
      // Vérifiez si l'utilisateur est authentifié et autorisez l'accès à la route
      // sinon redirigez vers la page de connexion
      let isAuthenticated = 0;
      if(localStorage.getItem('user') != null)
        isAuthenticated = 1;

      if (isAuthenticated) {
        next();
      } else {
        next('/login');
      }

    },
  },
  ...auth,

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes
})

export default router
