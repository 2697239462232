<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Equipement</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Collectivité</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Equipements</router-link></li>
            <li class="breadcrumb-item active">Fiche</li>
          </ol>
        </div>

      </div>
    </div>
  </div>

  <div v-if="equipement != null">
    <div class="profile-foreground position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg">
        <img src="../../assets/images/profile-bg.jpg" alt="" class="profile-wid-img" />
      </div>
    </div>
    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4 text-start">
      <div class="row g-4">
        <div class="col-auto">
          <div class="avatar-lg">
            <img :src="'http://apidistrict.klispay.home/uploads/'+equipement.photo" :alt="equipement.fullName" class="img-thumbnail rounded-circle" />
          </div>
        </div>
        <!--end col-->
        <div class="col">
          <div class="p-2">
            <h3 class="text-white mb-1">{{equipement.type.nom}}</h3>
            <div class="hstack text-white-50 gap-1">
              <div class="me-2"><i class="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>{{equipement.ramification.entite.nom}}</div>
              <div>
                <i class="ri-building-line me-1 text-white-75 fs-16 align-middle"></i>
                <span><span v-if="equipement.ramification.parent != null">{{equipement.ramification.parent.nom}}</span></span>  > {{ equipement.ramification.nom }} > {{equipement.numKlis}}
              </div>
            </div>
          </div>
        </div>
        <!--end col-->

        <!--end col-->

      </div>
      <!--end row-->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">
            <!-- Nav tabs -->
            <ul class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
              <li class="nav-item">
                <a class="nav-link fs-14 active" data-bs-toggle="tab" href="#overview-tab" role="tab">
                  <i class="ri-airplay-fill d-inline-block d-md-none"></i> <span class="d-none d-md-inline-block">Aperçu</span>
                </a>
              </li>
<!--              <li class="nav-item">
                <a class="nav-link fs-14" data-bs-toggle="tab" href="#projects" role="tab">
                  <i class="ri-price-tag-line d-inline-block d-md-none"></i> <span class="d-none d-md-inline-block">Echeances</span>
                </a>
              </li>-->
<!--              <li class="nav-item">
                <a class="nav-link fs-14" data-bs-toggle="tab" href="#documents" role="tab">
                  <i class="ri-folder-4-line d-inline-block d-md-none"></i> <span class="d-none d-md-inline-block">Transactions</span>
                </a>
              </li>-->
            </ul>

          </div>
          <!-- Tab panes -->
          <div class="tab-content pt-4 text-muted">
            <div class="tab-pane active" id="overview-tab" role="tabpanel">
              <div class="row">
                <div class="col-xxl-3">

                  <div class="card">
                    <div class="card-body text-start">
                      <h5 class="card-title mb-3">Info</h5>
                      <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                          <tbody>
                          <tr>
                            <th class="ps-0" scope="row">N° KLISPAY :</th>
                            <td class="text-muted">{{equipement.numKlis}}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Type :</th>
                            <td class="text-muted">{{equipement.type.nom}}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Précision :</th>
                            <td class="text-muted">{{equipement.precision}}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">N° équipement :</th>
                            <td class="text-muted">{{ equipement.noEquipement }}</td>
                          </tr>
                          <tr>
                            <th class="ps-0" scope="row">Date d'enrolement :</th>
                            <td class="text-muted">{{ equipement.createdAt.toString().slice(0,10) }}
                            </td>
                          </tr>
<!--                          <tr>
                            <th class="ps-0" scope="row">Piece d'identité</th>
                            <td class="text-muted">{{ equipement.typePieceIdentite }}</td>
                          </tr>-->
                          </tbody>
                        </table>
                      </div>
                    </div><!-- end card body -->
                  </div><!-- end card -->


                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-4">Contribuable</h5>
                      <div class="d-flex flex-wrap gap-2 fs-15" v-if="equipement.enrollements.length > 0">
                       {{equipement.enrollements[0].contribuable.fullName}}
                      </div>
                    </div><!-- end card body -->
                  </div><!-- end card -->

                </div>
                <!--end col-->
                <div class="col-xxl-9">

                  <div class="row">
                    <div class="col-lg-12 text-start">
                      <div class="card">
                        <div class="card-header align-items-center d-flex">
                          <h4 class="card-title mb-0  me-2">Situations</h4>

                        </div>
                        <div class="card-body text-start">
                          <div class="tab-content text-muted">
                            <div class="col-12 col-lg-auto order-last order-lg-0">
                              <div class="row text text-white-50 text-center">
                                <div class="card crm-widget text-start">
                                  <div class="card-body p-0 text-start">
                                    <div class="row row-cols-md-3 row-cols-1">
                                      <div class="col col-lg border-end bg-warning text-start text-white">
                                        <div class="py-4 px-3 text-start">
                                          <h5 class="text-muted text-uppercase fs-13">Total Prévu <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i>
                                          </h5>
                                          <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                              <i class="ri-money-dollar-box-line display-6 text-muted"></i>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                              <h2 style="color:#fff !important;" class="mb-0"><span class="counter-value" :data-target="equipement.situation.total">{{  formattedNumber((Number(equipement.situation.total) - Number(equipement.situation.payer)))  }}</span></h2>
<!--                                              <h2 class="mb-0"><span class="counter-value" >{{ tarif }}</span></h2>-->
                                            </div>
                                          </div>
                                        </div>
                                      </div><!-- end col -->
                                      <div class="col col-lg border-end text-start bg-success">
                                        <div class="py-4 px-3">
                                          <h5 style="color:#fff !important;" class="text-muted text-uppercase fs-13 text-white">Total payé <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i>
                                          </h5>
                                          <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                              <i style="color:#fff !important;" class="ri-money-dollar-box-line text-white display-6 text-muted"></i>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                              <h2 class="mb-0"><span class="counter-value text-white" :data-target="equipement.situation.payer">{{ formattedNumber(equipement.situation.payer) }}</span></h2>
                                            </div>
                                          </div>
                                        </div>
                                      </div><!-- end col -->
                                      <div class="col col-lg border-end text-start bg-danger">
                                        <div class="py-4 px-3">
                                          <h5 style="color:#fff !important;" class="text-muted text-uppercase fs-13">Total Restant <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i>
                                          </h5>
                                          <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0">
                                              <i style="color:#fff !important;" class="ri-money-dollar-box-line display-6 text-muted"></i>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                              <h2 style="color:#fff !important;" class="mb-0"><span class="counter-value" :data-target="equipement.situation.restant">{{  formattedNumber((Number(equipement.situation.total) - Number(equipement.situation.payer)))  }}</span></h2>
                                            </div>
                                          </div>
                                        </div>
                                      </div><!-- end col -->
                                    </div><!-- end row -->
                                  </div><!-- end card body -->
                                </div>
                              </div>

                            </div>
                          </div>
                        </div><!-- end card body -->
                      </div><!-- end card -->
                    </div><!-- end col -->
                  </div><!-- end row -->
                  <!-- With Controls -->
                  <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner" role="listbox">
                      <div v-for="base in equipement.equipements" :key="base" class="carousel-item active">
                        <img v-if="base.photo != null" :src="'https://api.klispay.com/public/uploads/'+base.photo" :alt="equipement.fullName" class="d-block img-fluid mx-auto" />
                      </div>


                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>


                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <!--end tab-pane-->
<!--            <div class="tab-pane fade" id="projects" role="tabpanel">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <table class="table table-borderless align-middle mb-0">
                      <thead class="table-light">
                      <tr>
                        <th>Année</th>
                        <th>Base taxable</th>
                        <th>Précision</th>
                        <th>Montant</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                &lt;!&ndash;end card-body&ndash;&gt;
              </div>
              &lt;!&ndash;end card&ndash;&gt;
            </div>-->
            <!--end tab-pane-->
<!--            <div class="tab-pane fade" id="documents" role="tabpanel">
              <div class="card text-start">
                <div class="card-body">
                  <div class="d-flex align-items-center mb-4">
                    <h5 class="card-title flex-grow-1 mb-0">Transactions</h5>

                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="table-responsive">
                        <table class="table mdi-table-border">
                          <thead>
                          <th>#</th>
                          <th>Date</th>
                          <th>ID transaction</th>
                          <th>Montant</th>
                          <th>Moyen de paiement</th>
                          <th>Agent</th>
                          <th>Statut</th>
                          </thead>
                          <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                      <div class="text-center mt-3">
                        <a href="javascript:void(0);" class="text-success"><i
                            class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load more </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->
            <!--end tab-pane-->
          </div>
          <!--end tab-content-->
        </div>
      </div>
      <!--end col-->
    </div>
  </div>
</template>

<script>
import Repository from '@/repository/EquipementRepository'
import TaxeRepository from '@/repository/TaxeRepository'
import moment from "moment";

export default {
  name: 'ficheEquipement',
  data(){
    return {
      tarif: null,
      repository: new Repository(),
      prevu: 0,
      taxeRepo: new TaxeRepository(),
      equipement: null,
      center: {},
      markers: [

      ]
    }
  },
  methods: {
    getTotalRestant(annee, echec){
      return this.getTotalEcheance(annee) + this.getTotalPayer(echec);
    },
    getTotalEcheance(annee){
      var sum = 0;

      this.equipement.echecs.filter(echec => echec.annee === annee ).map(item => item.montant).forEach(echeance => {
        sum = sum + Number(echeance)
      })
      return sum;
    },
    getTotalPayer(echec){
      var sum = 0;
      this.equipement.transactions.filter(item => this.formatDate(item.dateTransaction) === echec.annee && item.statut === 'Confirmé').map(trans => trans.montant).forEach(item => {
        sum  = sum + Number(item)
      })
      return sum;
    },
    formattedNumber(number) {
      if (number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return 0;
    },
    formatDate(date){
      return moment(date).format('YYYY')
    },
    printDate(dte){
      return moment(dte).format('DD-MM-YYYY HH:mm:ss')

    }

  },
  created() {
    this.repository.find(this.$route.params.id)
        .then(response => {
          this.equipement = response;
          console.log(this.equipement);
         /* this.equipement.contribEnrollements.forEach(item => {
            console.log(this.equipement);

            this.center = {lat: this.equipement.latitude, lng: this.equipement.longitude};
            this.markers.push({
              position: {
                lat: item.latitude, lng: item.longitude
              },
            })
          })*/
          this.taxeRepo.search([{colonne: 'te.id', operator: 'eq', value: this.equipement.type.id}], 'entx.nom', 'asc', 3000, 1)
              .then(responseR => {
                this.listTaxes = responseR.data;
                var application = {}
                if (this.listTaxes.length >0){
                  application =  this.listTaxes[0].applications[0];

                }

                var precision = application.precisione.find(item => item.libelle === this.equipement.precision)
                this.tarif = precision.tarif;
              //  console.log( precision );

              })

          //  this.center = {lat: this.equipement.latitude, lng: this.equipement.longitude};
          /* this.markers = [
             {
               position: {
                 lat: this.equipement.latitude, lng: this.equipement.longitude
               },
             }
             , // Along list of clusters
           ]*/

        })
        .catch(error => {
          console.log(error)
        })

  }
}
</script>