import API from '@/helpers/Api';
import User from '@/entity/User';

export default class TypeTaxeRepository extends API {

    user = new User();

    orderByOptions = [
        {text: 'id', value: 'id', name: 'tu.id'},
        {text: 'nom', value: 'nom', name: 'tu.nom'},
        {text: 'description', value: 'description', name: 'tu.description'},
        {text: 'enabled', value: 'enabled', name: 'tu.enabled'},
        {text: 'createdAt', value: 'createdAt', name: 'tu.createdAt'}
    ];
    create(data) {
        const type_users = this.post('/v2/type_users', data);
        return type_users;
    }
    edit(data, id){
        const type_users = this.patch('/v2/type_users/'+id, data)
        return type_users;
    }
    find(id){
        const type_users = this.findOne('/v2/type_users/'+id)
        return type_users;
    }
    supprimer(id){
        const type_users = this.delete('/v2/type_users/'+id)
        return type_users;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        const type_users = this.listing('/v2/type_users', fields, orderBy, sortOrder, itemPerPage, currentPage)
        //console.log(ramifications);
        return type_users;
    }
     
}
