<template>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 class="mb-sm-0">Collectivité</h4>
  
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Administration</router-link></li>
              <li class="breadcrumb-item active">Collectivité</li>
            </ol>
          </div>
  
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <form @submit.prevent="createCategorie">
            <div class="card card-animate">
  
              <div class="card-body">
                <div class="row text-start">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Nom</label>
                    <input v-model="entite.nom" type="text" class="form-control" required>
                    <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                  </div>
                </div>
                <div class="row text-start mt-2">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Nom court</label>
                    <input v-model="entite.nomCourt" type="text" class="form-control" required>
                    <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                  </div>
                </div>
                <div class="row text-start mt-2">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Code</label>
                    <input v-model="entite.code" type="text" class="form-control" required>
                    <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                  </div>
                </div>
  
                <div class="row text-start mt-3">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Ville</label>
                    <select v-model="entite.ville" type="text" class="form-control" required>
                      <option :key="item.id" :value="item.id" v-for="item in listVilles">{{item.nom}}</option>
                    </select>
                    <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                  </div>
                </div>
                <div class="row text-start mt-3">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Adresse</label>
                    <input v-model="entite.adresse" type="text" class="form-control" />
                    <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                  </div>
                </div>
                <div class="row text-start mt-3">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Commission</label>
                    <input v-model="entite.commission" type="text" class="form-control" />
                    <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                  </div>
                </div>
                <div class="row text-start mt-3">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Telephone</label>
                    <input v-model="entite.telephone" type="text" class="form-control" />
                    <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <div class="form-check form-switch form-switch-success">
                      <input  v-model="entite.enabled" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" >
                      Active ?
                    </div>
                  </div>
                </div>
  
              </div>
              <div class="card-footer">
                <div class="row">
                  <button type="submit" class="btn btn-primary">Enregistrer</button>
  
                </div>
              </div>
            </div>
  
          </form>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Entite from "@/entity/Entite";
  import Repository from '@/repository/EntiteRepository'
  import VilleRepository from '@/repository/VilleRepository'
  export default {
    name: 'createCategorieActivitePage',
    data() {
      return {
        entite: new Entite(),
        repository: new Repository(),
        villeRepo: new VilleRepository(),
        listVilles: null
  
  
      }
    },
    computed: {
    },
    methods: {
      createCategorie(){
        this.entite.type = 1;
        this.repository.create(this.entite);
        this.$router.push({ name: 'listeEntite' })
      }
  
    },
    created() {
  
      this.villeRepo.search([{ colonne: 'v.enabled', operator: 'eq', value: 1 }], 'v.nom', 'asc', 30, 1)
          .then(response => {
            this.listVilles = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    }
  }
  </script>