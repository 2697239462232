<template>
  <loading v-model:active="isLoading"
           :can-cancel="true"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <div class="row">
    <div class="col-12">
      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Relevés</router-link></li>
          <li class="breadcrumb-item active">Liste des équipements payés</li>
        </ol>
      </div>
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          <span v-if="entite !=null">{{ entite.text }}</span>
          <span v-if="departement !=null"> > {{ departement.text }}</span>
          <span v-if="quartier !=null"> > {{ quartier.text }}</span>
          <span v-if="zone !=null"> > {{ zone.text }}</span>
        </h4>

        <div class="page-title-right">
          <div>
            <div style="display: inline">
              <button v-if="checkDate('day')"   @click="filterByDay" class="btn btn-primary ">
                Aujourd'hui
              </button>
              <button v-else   @click="filterByDay" class="btn btn-soft-secondary ">
                Aujourd'hui
              </button>
              <button  v-if="checkDate('week')"   @click="filterByWeek" class="btn btn-primary ">
                Cette semaine
              </button>
              <button  v-else   @click="filterByWeek" class="btn btn-soft-secondary ">
                Cette semaine
              </button>
              <button v-if="checkDate('month')"  @click="filterByMonth" class="btn btn-primary ">
                Ce mois
              </button>
              <button v-else  @click="filterByMonth" class="btn btn-soft-secondary ">
                Ce mois
              </button>
              <button v-if="checkDate('year')"  @click="filterByYear" class="btn btn-primary ">
                Cette année
              </button>
              <button v-else  @click="filterByYear" class="btn btn-soft-secondary ">
                Cette année
              </button>
              <div  class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical align-middle"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                  <li><button class="dropdown-item" @click="filterByYesterday">Hier</button></li>
                  <li><button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button></li>
                  <li><button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button></li>
                  <li><Flatpickr
                      @change="onDateChange"
                      v-model="daterange"
                      :config="config"
                      class="form-control "
                      placeholder="Select date"/></li>
                </ul>
              </div>
            </div>


          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="row">
          <div class="col-md-6">
            <Select2 :placeholder="'Collectivité'" v-model="selectedEntite" :options="listCollectivites"
                     @select="selectEntite($event)" @change="changeEntite($event)"/>
          </div>
          <div class="col-md-6">
            <Select2 :placeholder="'Tous les departements'" v-model="selectedDepartement" :options="listDepartements"
                     @select="selectDepartement($event)" @change="changeDepartement($event)"/>
          </div>

        </div>

        <div class="row mt-2">
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les quartiers'" v-model="selectedQuartier" :options="listQuartiers"
                     @select="selectQuartier($event)" @change="changeQuartier($event)"/>
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les zones'" v-model="selectedZone" :options="listZones"
                     @select="selectZone($event)" @change="changeZone($event)"/>
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les secteurs'" v-model="selectedSecteur" :options="listSecteurs"
                     @select="selectedSecteur($event)" @change="changeSecteur($event)"/>
          </div>
        </div>
        <div class="row mt-2">
          <!--          <div class="col-md-6 text-start">
                      <label class="text-start">Activités</label>
                      <Select2MultipleControl :placeholder="'Toutes les activités'" v-model="selectedActivite" @select="selectActivite($event)" @change="changeActivite($event)" :options="listNatureActivites"/>
                    </div>-->
          <div class="col-md-12">
            <Select2MultipleControl :placeholder="'Tous les equipements'" v-model="selectedEquipement" @select="selectEquipement($event)" @change="changeEquipement($event)" :options="listTypeEquipements"/>
          </div>

        </div>

      </div><!-- end col -->
    </div>



  </div>



  <div class=" text-end mt-3">
    <button type="submit" class="btn btn-success float-right" @click="envoyerRequete">Envoyer</button>
  </div>
  <br/>
  <div class="row mt-3">

    <div class="col-md-12">
      <div class="table-card">
        <table class="table table-borderless align-middle mb-0">
          <tr >
            <td><a target="_blank" style="font-size: 15px" :href="chemin(listReleves)">{{listReleves}}</a> </td>

          </tr>
        </table>
      </div>
    </div>
  </div>
  <br/>
</template>

<script>
import html2pdf from "html2pdf.js"
import Loading from 'vue-loading-overlay';

import 'flatpickr/dist/flatpickr.css';
import fr from "flatpickr/dist/l10n/fr";
import RamificationRepository from "@/repository/RamificationRepository";
import DashboardRepository from "@/repository/DashboardRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import NatureActiviteRepository from "@/repository/NatureActiviteRepository";
import Repository from '@/repository/ContribuableRepository';
import moment from "moment/moment";
import TypeEquipementRepository from "@/repository/TypeEquipementRepository";
import ReleveRepository from "@/repository/ReleveRepository";
import Select2MultipleControl from "v-select2-multiple-component";
import Select2 from "vue3-select2-component";
import Flatpickr from "vue-flatpickr-component";
/*import Flatpickr from "vue-flatpickr-component";*/

export default {
  name: 'ImpressionListeContribuablePage',
  computed: {

  },
  data(){
    return{
      from: null,
      to: null,
      listContribuables: null,
      repository: new Repository(),
      releveRepository: new ReleveRepository(),
      listDepartements: [],
      listNatureActivites: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],
      isLoading: false,

      equipementId: null,
      activiteId: null,
      entite: null,
      equipement: null,
      activite: null,
      departement: null,
      quartier: null,
      ms: [],
      zone:null,
      secteur: null,
      selectedEntite: null,
      selectedEquipement: null,
      selectedActivite: null,
      daterange: null,
      selectedDepartement: null,
      selectedQuartier: null,
      selectedZone: null,
      selectedSecteur: null,
      ramificationRepo: new RamificationRepository(),
      dashboardRepo: new DashboardRepository(),
      entiteRepo: new EntiteRepository(),
      tequipementRepo: new TypeEquipementRepository(),
      natureActiviteRepo: new NatureActiviteRepository(),
      myValue: '',
      listCollectivites: [],
      listTypeEquipements: [],
      listReleves: [],

      config: {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        // defaultDate:[new Date(), new Date().setDate(new Date().getDate() + 7)]
      }
    }
  },
  methods:{
    envoyerRequete(){
      this.isLoading = true;
      var val = {entite: this.selectedEntite, ramification: this.ms, equipement: this.selectedEquipement, from: this.from, to: this.to, user: this.user.fullName}
      this.releveRepository.paye_equipement(val)
          .then(response => {
            // console.log(response);
            this.listReleves = response.data;
            this.isLoading=false;

          })
      //console.log(val)
    },
    selectEntite(val){
      this.entite = val;
      this.isLoading=true;
      //  this.selectedEntite = val;

      this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null', value: ''}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom});
              // this.isLoading=false;
              this.isLoading=false;
            })
          });
      this.isLoading = false;
    },
    changeEntite(val){
      this.entite = val;
      this.selectedEntite = val;
    },
    changeActivite(val){
      //  this.entite = val;
      this.selectedActivite = val;
    },
    selectActivite(val){
      console.log(val)
      //this.entite = val;
      //  this.isLoading=true;

    },
    changeEquipement(val){
      //  this.entite = val;
      this.selectedEquipement = val;
    },
    selectEquipement(val){
      console.log(val)
      //this.entite = val;
      //  this.isLoading=true;

    },
    changeDepartement(val){
      this.selectedDepartement = val;
    },
    async selectDepartement(val){
      this.isLoading = true;
      this.departement = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];

      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 3000, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})
              this.ms = this.listQuartiers.map(item => item.id);
              this.ms.push(val.id)

            })
            this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listQuartiers.map(item => item.id)}], 'ent.id', 'asc', 3000, 1)
                .then(zones => {
                  //  var listZones = [];
                  //  listZones = zones.data
                  this.listZones = zones.data;
                  zones.data.forEach(item => {
                    this.ms.push(item.id);
                  })

                })
            this.ramificationRepo.search([{colonne: 'p.id', operator: 'in', value: this.listZones.map(item => item.id)}], 'ent.id', 'asc', 3000, 1)
                .then(secteurs => {
                  secteurs.data.forEach(item => {
                    this.ms.push(item.id);
                  })

                })
            this.isLoading=false;


          })
    },
    changeQuartier(val){
      this.selectedQuartier = val;
    },
    selectQuartier(val){
      //this.isLoading=true;
      this.isLoading = true;

      this.quartier = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
              this.ms = this.listZones.map(item => item.id);
              this.ms.push(val.id)
            })
            this.isLoading=false;

          })


    },
    changeZone(val){
      this.selectedZone = val;
    },
    selectZone(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
              this.ms = this.listSecteurs.map(item => item.id);
              this.ms.push(val.id)
            })
            this.isLoading=false;


          })

    },
    changeSecteur(val){
      this.selectedSecteur = val;
    },
    selectSecteur(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom});
              this.ms = val.id

            })
          })
      this.dashboardRepo.dashboardByRamification(val.id, this.daterange, this.selectedAnnee.text)
          .then(response => {
            this.dashboard = response[0];
            console.log(this.dashboard)
          })
      this.dashboardRepo.dashboardDetailsByRamification(val.id, this.daterange, this.selectedAnnee.text)
          .then(response => {
            this.tableau = response;
            this.isLoading=false
          })
    },
    chemin(file){
   //   var collectivite = this.listCollectivites.find(item => item.id === Number(this.selectedEntite)).text
      //  console.log(collectivite)
        return 'https://apiclassic.klispay.tech/api/v2/download?path='+file;

    },
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        filename: "liste-des-contribuables.pdf",
        jsPDF:        { unit: 'mm', format: 'a4', orientation: 'landscape' }
      });
    },

    onDateChange(){
      this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
      this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange;
    },
    checkDate(type){
      if (type === 'day')
        return this.daterange === moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD") || this.daterange === moment().format("YYYY-MM-DD")
      if (type === 'week')
        return this.daterange === moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD")
      if (type === 'month')
        return this.daterange ===moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD")
      if (type === 'year')
        return this.daterange ===moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD")

    },
    filterByDay() {
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
      this.from = this.to = moment().format("YYYY-MM-DD")

      // console.log(this.daterange)
    },
    filterByYesterday() {
      this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
      this.from = moment().subtract(1, 'days').format("YYYY-MM-DD")
      this.to = moment().subtract(1, 'days').format("YYYY-MM-DD")

      //console.log(this.daterange)
    },
    filterByLastWeek() {
      this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
      this.from = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD")
      this.to = moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");

      //console.log(this.daterange)
    },
    filterByLastMonth() {
      this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      this.from = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD")
      this.to = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")

      //console.log(this.daterange)
    },
    filterByWeek() {

      this.daterange = moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek').format("YYYY-MM-DD");
      this.from = moment().startOf('isoWeek').format("YYYY-MM-DD")
      this.to = moment().endOf('isoWeek').format("YYYY-MM-DD")
      // console.log(this.daterange);

    },
    filterByMonth() {
      this.daterange = moment().startOf('month').format("YYYY-MM-DD") + ' au ' + moment().endOf('month').format("YYYY-MM-DD");
      this.from = moment().startOf('month').format("YYYY-MM-DD")
      this.to = moment().endOf('month').format("YYYY-MM-DD")

    },
    filterByYear() {
      this.daterange = moment().startOf('year').format("YYYY-MM-DD") + ' au ' + moment().endOf('year').format("YYYY-MM-DD");
      this.from = moment().startOf('year').format("YYYY-MM-DD")
      this.to = moment().endOf('year').format("YYYY-MM-DD")

      console.log(' au ' + moment().endOf('year').format("YYYY-MM-DD"))
    },

    formattedNumber(number) {
      if (number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return 0;
    },




  },
  mounted() {
    this.entiteRepo.search([{colonne: '', operator: '', value: ''}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.listCollectivites =  response.data.map(item => ({id: item.id, text: item.nom}));
          //  console.log(this.listCollectivites)

        })
    this.natureActiviteRepo.search([{colonne: '', operator: '', value: ''}], 'na.nom', 'asc', 3000, 1)
        .then(response => {
          this.listNatureActivites =  response.data.map(item => ({id: item.id, text: item.nom}));
          //    console.log(this.listCollectivites)

        })
    this.tequipementRepo.search([{colonne: '', operator: '', value: ''}], 'teq.nom', 'asc', 3000, 1)
        .then(response => {
          this.listTypeEquipements =  response.data.map(item => ({id: item.id, text: item.nom}));
          //    console.log(this.listCollectivites)

        })


  },
  created() {
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
    this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment(). format("YYYY-MM-DD");

    this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
    this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange.split('au')[1];



  },
  components:{
    Flatpickr,
    Select2MultipleControl,
    Select2,
    Loading,

    /* Flatpickr,*/
  }
}
</script>