<template>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 class="mb-sm-0">Pays</h4>
  
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Administration</router-link></li>
              <li class="breadcrumb-item active">Pays</li>
            </ol>
          </div>
  
        </div>
      </div>
    </div>
  
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <form @submit.prevent="editPays">
            <div class="card card-animate">
  
              <div class="card-body">
                <div class="row text-start">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Nom</label>
                    <input v-model="pays.nom" type="text" class="form-control" required>
                    <div class="invalid-feedback">Veuillez entrer le nom du pays</div>
                  </div>
                </div>                        
                <div class="row text-start mt-3">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Description</label>
                    <textarea v-model="pays.description" type="text" class="form-control" ></textarea>
                    <div class="invalid-feedback">Veuillez entrer le nom de la description</div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <div class="form-check form-switch form-switch-success">
                      <input  v-model="pays.enabled" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" >
                      Active ?
                    </div>
                  </div>
                </div>
  
              </div>
              <div class="card-footer">
                <div class="row">
                  <button type="submit" class="btn btn-primary">Enregistrer</button>
  
                </div>
              </div>
            </div>
  
          </form>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Pays from "@/entity/Pays";
  import Repository from '@/repository/PaysRepository'
  /*import EntiteRepository from '@/repository/EntiteRepository'
  import CategorieActiviteRepository from '@/repository/CategorieActiviteRepository'*/
  export default {
    name: 'createPaysPage',
    data() {
      return {
        pays: new Pays(),
        repository: new Repository(),
        /*categorieRepo: new CategorieActiviteRepository(),
        entiteRepo: new EntiteRepository(),
        listCollectivite: null,
        listCategorieActivite: null*/
  
  
      }
    },
    computed: {
    },
    methods: {
      editPays(){
        this.repository.edit(this.pays, this.$route.params.id);
        this.$router.push({ name: 'pays' })
      }
  
    },
    created() {
      /*this.entiteRepo.search([{ colonne: '', operator: '', value: '' }], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            this.listCollectivite = response.data;
            //   console.log(this.listCollectivite);
          })
          .catch(error => {
            console.log(error);
          });
      this.categorieRepo.search([{ colonne: 'ca.enabled', operator: 'eq', value: 1 }], 'ca.nom', 'asc', 30, 1)
          .then(response => {
            this.listCategorieActivite = response.data;
            //   console.log(this.listCollectivite);
          })
          .catch(error => {
            console.log(error);
          });*/
      this.repository.find(this.$route.params.id)
          .then(response => {
            this.pays = response;
            //this.natureActivite.entite = this.natureActivite.entite.id;
            //this.natureActivite.categorie = this.natureActivite.categorie.id;
  
          });
    }
  }
  </script>