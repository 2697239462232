import API from '@/helpers/Api';

export default class MoyenPaiement {
    id;
    nom;
    description;
   
    commission;
    enabled;
    createdAt;
    updatedAt;
    api = new API();
    constructor() {
        this.id = null;
        this.nom = null;
        this.description = null;
        this.commission = null;
        this.enables = false;
    }
}