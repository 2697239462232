<template>

  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Utilisateurs</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Administration</router-link></li>
            <li class="breadcrumb-item active">Agents</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <form @submit.prevent="createCollectivite">
      <div class="card">
        <div class="card-body">
          <div class="row text-start">
            <div class="col-sm-12">
              <label for="firstName" class="form-label">Collectivité</label>
              <select v-model="user.entites" class="form-control form-select">
                <option :value="item.id" v-for="item in listCollectivites" :key="item">{{item.nom}}</option>
              </select>
              <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
            </div>

          </div>
            <div class="row mt-2 text-start">
                <div class="col-sm-12">
                    <label for="firstName" class="form-label">Roles</label>
                    <Select2   v-model="user.groupe" :options="listRoles"  class="form-select form-select-sm"></Select2>

                    <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                </div>

            </div>

          <div class="row mt-2 text-start">
            <div class="col-sm-6">
              <label for="firstName" class="form-label">Nom</label>
              <input v-model="user.nom" type="text" class="form-control" required>
              <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
            </div>
            <div class="col-sm-6">
              <label for="firstName" class="form-label">Prenom</label>
              <input v-model="user.prenom" type="text" class="form-control" required>
              <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
            </div>
          </div>
          <div class="row mt-2 text-start">
            <div class="col-sm-6">
              <label for="firstName" class="form-label">Adresse E-mail</label>
              <input v-model="user.email" type="text" class="form-control" required>
              <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
            </div>
            <div class="col-sm-6">
              <label for="firstName" class="form-label">Nom d'utilisateur</label>
              <input v-model="user.username" type="text" class="form-control" required>
              <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
            </div>
          </div>
          <div class="row mt-2 text-start">
            <div class="col-sm-12">
              <label for="firstName" class="form-label">Affectation</label>
              <select v-model="user.ramifications" class="form-control form-select" multiple>
                <option :value="item.id" v-for="item in listRamifications" :key="item">{{item.nom}}</option>
              </select>
              <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="form-check form-switch form-switch-success">
                <input  v-model="user.enabled" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" >
                Active ?
              </div>
            </div>
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <button type="submit" class="btn btn-primary">Enregistrer</button>

          </div>
        </div>

      </div>
    </form>
  </div>
</template>
<script>
import User from "@/entity/User";
import UserRepository from "@/repository/UserRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import RamificationRepository from "@/repository/RamificationRepository";
import RoleRepository from "@/repository/GroupRepository";
import Select2 from "vue3-select2-component";

export default {
  name: 'createAgentPage',
  data(){
    return{
      user: new User(),
      repository: new UserRepository(),
      entiteRepo: new EntiteRepository(),
      ramificationRepo: new RamificationRepository(),
      roleRepository: new RoleRepository(),
      listCollectivites: [],
      listRamifications: [],
      listRoles: [],
    }
  },
  methods: {
    createCollectivite(){
      this.user.type = 3;
      delete this.user.password;
      this.user.entites = [this.user.entites];

      this.repository.edits(this.user, this.$route.params.id)
          .then(response => {
              if(response.id != null){
                  this.$router.push({ name: 'listUsers' })

              }
          })

    }
  },
  created() {
    this.repository.find(this.$route.params.id)
        .then(response =>{
          this.user = response;
         // this.user.entites = response.entites[0].id
          this.user.groupe = response.groupe.id
          this.user.ramifications = response.ramifications.map(item => item.id);
          //console.log(this.user);
        })
    this.entiteRepo.search([{colonne: '', operator: '', value: ''}], 'ent.nom', 'asc', '30', 1)
        .then(response =>{
          this.listCollectivites = response.data;
        })
    this.ramificationRepo.search([{colonne: '', operator: '', value: ''}], 'ent.nom', 'asc', '400', 1)
        .then(response =>{
          this.listRamifications = response.data;
        })
      this.roleRepository.search([{colonne: '', operator: '', value: ''}], 'g.nom', 'asc', '400', 1)
          .then(response =>{
              this.listRoles = response.data.map(item => ({id: item.id, text: item.nom}) )
          })

  },
    components:{
        Select2

    }
}
</script>