<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Impressions</router-link></li>
          <li class="breadcrumb-item active">Situation des impayés</li>
        </ol>
      </div>
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          <span v-if="entite !=null">{{ entite.text }}</span>
          <span v-if="departement !=null"> > {{ departement.text }}</span>
          <span v-if="quartier !=null"> > {{ quartier.text }}</span>
          <span v-if="zone !=null"> > {{ zone.text }}</span>
        </h4>

        <div class="page-title-right">
          <div>
            <div style="display: inline">
              <button v-if="checkDate('day')"   @click="filterByDay" class="btn btn-primary ">
                Aujourd'hui
              </button>
              <button v-else   @click="filterByDay" class="btn btn-soft-secondary ">
                Aujourd'hui
              </button>
              <button  v-if="checkDate('week')"   @click="filterByWeek" class="btn btn-primary ">
                Cette semaine
              </button>
              <button  v-else   @click="filterByWeek" class="btn btn-soft-secondary ">
                Cette semaine
              </button>
              <button v-if="checkDate('month')"  @click="filterByMonth" class="btn btn-primary ">
                Ce mois
              </button>
              <button v-else  @click="filterByMonth" class="btn btn-soft-secondary ">
                Ce mois
              </button>
              <button v-if="checkDate('year')"  @click="filterByYear" class="btn btn-primary ">
                Cette année
              </button>
              <button v-else  @click="filterByYear" class="btn btn-soft-secondary ">
                Cette année
              </button>
              <div  class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical align-middle"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                  <li><button class="dropdown-item" @click="filterByYesterday">Hier</button></li>
                  <li><button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button></li>
                  <li><button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button></li>
                  <li><Flatpickr
                      @change="onDateChange"
                      v-model="daterange"
                      :config="config"
                      class="form-control "
                      placeholder="Select date"/></li>
                </ul>
              </div>
            </div>


          </div>

        </div>

      </div>
    </div>
  </div>

<!--  <div class="row">
    <div class="col-md-4">
      <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
    </div>
  </div>


  <div class="row mt-2">
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectedSecteur($event)" @change="changeSecteur($event)"  />
    </div>
    <div class="col-md-4">
      <div class="input-group">
        <Flatpickr
            @change="onDateChange"
            v-model="daterange"
            :config="config"
            class="form-control "
            placeholder="Select date"/>
        <div class="input-group-text bg-primary border-primary text-white">
          <i class="ri-calendar-2-line"></i>
        </div>
      </div>

    </div>
  </div>-->
  <div class="row">
    <div class="col-md-6">
      <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
    </div>
    <div class="col-md-6">
      <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
    </div>

  </div>

  <div class="row mt-2">
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
    </div>
    <div class="col-md-4">
      <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectedSecteur($event)" @change="changeSecteur($event)"  />
    </div>
  </div>
  <br/>
  <div class="row">
    <button @click="exportToPDF" class="btn btn-icon btn-success"><i class="ri-file-pdf-fill"></i> </button>
  </div>
  <div id="element-to-convert" >
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="card pricing-box">
          <div class="card-body bg-light m-2 p-4">
            <h2 class="text-uppercase">Situations des impayés <span v-if="entite != null"> {{entite.text}}</span> </h2>
            <table class="table table-borderless text-start">
              <thead>
              <tr>
                <th>Departement</th>
                <th>Quartier</th>
                <th>Zone</th>
                <th>Secteur</th>
                <th>Généré le</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><span v-if="departement != null">{{departement.text}}</span></td>
                <td><span v-if="quartier != null">{{quartier.text}}</span></td>
                <td><span v-if="secteur != null">{{secteur.text}}</span></td>
                <td><span v-if="zone != null">{{zone.text}}</span></td>
                <td>{{new Date().getDate() + '-' + new Date().getMonth()+1 + '-' + new Date().getFullYear() }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <table class="table text-start mb-0 table-bordered">
        <thead class="table-light">
        <tr class="text-center" >

          <th class="bg-success" scope="col" >#</th>
          <th class="bg-success" scope="col">Date</th>
          <th class="bg-success" scope="col">Heure</th>
          <th class="bg-success" scope="col" colspan="4">Contribuable</th>
          <th class="bg-success" scope="col" >Base taxable</th>
          <th class="bg-success" colspan="3" scope="col">Situations fiscale</th>
          <th class="bg-success" colspan="2" scope="col">Agent</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th>N° KlisPay</th>
          <th>Nom/Raison sociale</th>
          <th>Emplacement</th>
          <th>Statut</th>
          <th></th>
          <th>Prevu</th>
          <th>Payer</th>
          <th>Restant</th>
          <th>Pseudo</th>
          <th>Nom</th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in listPayeur" :key="item.id">
          <td>{{ index+1 }}</td>
          <td>{{ item.createdAt.toString().slice(0,10) }}</td>
          <td>{{ item.createdAt.toString().slice(11,17) }}</td>
          <td><router-link :to="{name: 'ficheContribuables', params: { id: item.id }}"> {{item.numKlis}}</router-link></td>
          <td>{{item.fullName}}</td>
          <td>{{item.ramification.nom}}</td>
          <td>
            <span v-if="item.enabled===true" class="badge bg-success">Confirmé</span>
            <span v-else class="badge bg-danger">En attente de confirmation</span>
          </td>
          <td>
            <span v-html="item.baseTaxables"></span>

          </td>

          <td>{{ formattedNumber(item.situation.total) }}</td>
          <td>{{ formattedNumber(item.situation.payer) }}</td>
          <td>{{ formattedNumber(item.situation.restant) }}</td>
          <td>{{ item.createdBy.username }}</td>
          <td>{{ item.createdBy.fullName }}</td>
          <td></td>
          <td></td>
        </tr>

        </tbody>
        <tfoot class="table-light">
        <tr class="text-center" >

          <th class="bg-success" scope="col" colspan="8" >TOTAL</th>

          <th class="bg-success">{{totalPrevu}}</th>
          <th class="bg-success">{{totalPayer}}</th>
          <th class="bg-success">{{totalRestant}}</th>
          <th class="bg-success"></th>
          <th class="bg-success"></th>
        </tr>
        </tfoot>
      </table>

    </div>

  </div>

</template>

<script>
import html2pdf from "html2pdf.js"

import Select2 from 'vue3-select2-component';
import 'flatpickr/dist/flatpickr.css';
import fr from "flatpickr/dist/l10n/fr";
import RamificationRepository from "@/repository/RamificationRepository";
import DashboardRepository from "@/repository/DashboardRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import NatureActiviteRepository from "@/repository/NatureActiviteRepository";
import Repository from '@/repository/ContribuableRepository';
import moment from "moment/moment";
import TypeEquipementRepository from "@/repository/TypeEquipementRepository";
/*import Flatpickr from "vue-flatpickr-component";*/

export default {
  name: 'ImpressionListeContribuablePage',
  computed: {
    listPayeur(){
      if(this.listContribuables != null)
        return this.listContribuables.filter(item => parseInt(item.situation.restant) > 0)
      else
        return null;

    },
    totalPrevu(){
      let sum = 0;

      if (this.listPayeur != null)
        for (const item of this.listPayeur) {
        sum += (item != null) ? parseInt(item.situation.total) : 0;
      }
      return this.formattedNumber(sum);
    },
    totalPayer(){
      let sum = 0;
      if (this.listPayeur != null)
        for (const item of this.listPayeur) {
        sum += (item != null) ? parseInt(item.situation.payer) : 0;
      }
      return this.formattedNumber(sum);
    },
    totalRestant(){
      let sum = 0;
      if (this.listPayeur != null)
        for (const item of this.listPayeur) {
          sum += (item != null) ? parseInt(item.situation.restant) : 0;
        }
      return this.formattedNumber(sum);
    },
  },
  data(){
    return{
      listContribuables: null,
      repository: new Repository(),
      equipementId: null,
      activiteId: null,
      entite: null,
      departement: null,
      quartier: null,
      ms: [],
      zone:null,
      secteur: null,
      selectedEntite: null,
      selectedEquipement: null,
      selectedActivite: null,
      daterange: null,
      selectedDepartement: null,
      selectedQuartier: null,
      selectedZone: null,
      selectedSecteur: null,
      ramificationRepo: new RamificationRepository(),
      dashboardRepo: new DashboardRepository(),
      entiteRepo: new EntiteRepository(),
      tequipementRepo: new TypeEquipementRepository(),
      natureActiviteRepo: new NatureActiviteRepository(),
      myValue: '',
      listCollectivites: [],
      listDepartements: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],
      listEquipements: [],
      listNatureActivites: [],
      config: {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        // defaultDate:[new Date(), new Date().setDate(new Date().getDate() + 7)]
      }
    }
  },
  methods:{
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        filename: "liste-des-contribuables.pdf",
        jsPDF:        { unit: 'mm', format: 'a4', orientation: 'landscape' }
      });
    },
    async fillTable(){
      try {
        this.ms =  []
        //console.log(this.$route.query.departement);
        if (this.entite != null && this.departement === null) {
         // this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'ent.id'), 1)

        //  this.fields.push({colonne: 'ent.id', operator: 'eq', value: this.entite.id});

        }
        if (this.departement != null && this.quartier === null){
          this.ms = this.listQuartiers.map(item => item.id);
          this.ms.push(this.departement.id)

        }
        if (this.quartier != null && this.zone === null){
          this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'ram.id'), 1)
          this.fields.push({colonne: 'ram.id', operator: 'in', value:  this.ms});

          this.ms = this.listZones.map(item => item.id);
          this.ms.push(this.quartier.id)
          this.repository.findOne("/v2/contribuables/enrollement/report?entite="+this.entite.id+"&ramification="+this.ms+"&from="+this.from+"&to="+this.to)
              .then(response => {
                this.report = response;
              })

        }
        if (this.zone != null && this.secteur === null){
          this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'ram.id'), 1)
          this.fields.push({colonne: 'ram.id', operator: 'in', value:  this.ms});

          this.ms = this.listSecteurs.map(item => item.id);
          this.ms.push(this.zone.id)

        }

        if (this.secteur != null ){
          this.fields = this.fields.splice(this.fields.find(item => item.colonne === 'ram.id'), 1)

          this.fields.push({colonne: 'ram.id', operator: 'eq', value:  this.secteur});

        }

        const trResponse = await this.repository.search(this.fields, 'contrib.nom', 'asc', 5000, 1);
        //console.log(trResponse)
        this.listContribuables = trResponse.data;
        //console.log(this.listContribuables);
        this.totalPages = Math.ceil(trResponse.meta.total_records / this.itemsPerPage);

      }catch (e) {
        console.log(e);
      }
    },
    onDateChange(){
      this.fillTable();
    },
    checkDate(type){
      if (type === 'day')
        return this.daterange === moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD") || this.daterange === moment().format("YYYY-MM-DD")
      if (type === 'week')
        return this.daterange === moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD")
      if (type === 'month')
        return this.daterange ===moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD")
      if (type === 'year')
        return this.daterange ===moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD")

    },
    filterByDay() {
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
      this.fillTable();
      // console.log(this.daterange)
    },
    filterByYesterday() {
      this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
      this.fillTable();
      //console.log(this.daterange)
    },
    filterByLastWeek() {
      this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
      this.fillTable();
      //console.log(this.daterange)
    },
    filterByLastMonth() {
      this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      this.fillTable();
      //console.log(this.daterange)
    },
    filterByWeek() {
      this.daterange = moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD");
      // console.log(this.daterange);
      this.fillTable();
    },
    filterByMonth() {
      this.daterange = moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD");
      // console.log(this.daterange);
      this.fillTable();
    },
    filterByYear() {
      this.daterange = moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD");
      this.fillTable();
      console.log( ' au ' + moment().endOf('year'). format("YYYY-MM-DD"))
    },

    formattedNumber(number) {
      if (number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return 0;
    },
    selectEquipement(val){
      this.equipementId = val.id
      this.fillTable()
    },

    selectActivite(val) {
      this.activiteId = val.id
      this.fillTable()
    },

    selectEntite(val){
      this.entite = val;
      this.isLoading=true;
      //  this.selectedEntite = val;

      this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null', value: ''}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom});
              this.fillTable();
            })
          });
    },
    changeActivite(val) {
      this.activiteId = val.id;
      this.fillTable()
    },
    changeEquipement(val){
      this.equipementId = val.id;
      this.fillTable()
    },
    changeEntite(val){
      this.entite = val;
      this.selectedEntite = val;
    },
    changeDepartement(val){
      this.selectedDepartement = val;
    },
    async selectDepartement(val){
      this.isLoading = true;
      this.departement = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})

            })
            this.fillTable();

          })
    },
    changeStatut(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeCollectivite(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeRamification(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeQuartier(val){
      this.selectedQuartier = val;
    },
    selectQuartier(val){
      this.isLoading=true;
      this.quartier = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
            })
            this.fillTable()
          })


    },
    changeZone(val){
      this.selectedZone = val;
    },
    selectZone(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
            })
            this.fillTable()
          })

    },
    contribuables()
    {
      return this.listContribuables.filter(item => item.statut='Validé');
    },
  },
  created() {
    this.entiteRepo.search([{colonne: '', operator: '', value: ''}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom})

          })
        })
    this.natureActiviteRepo.search([{colonne: '', operator: '', value: ''}], 'na.nom', 'asc', 30, 1)
        .then(response => {
          response.data.forEach(item => {
            this.listNatureActivites.push({id: item.id, text: item.nom})

          })
        })
    this.tequipementRepo.search([{colonne: '', operator: '', value: ''}], 'teq.nom', 'asc', 30, 1)
        .then(response => {
          response.data.forEach(item => {
            this.listEquipements.push({id: item.id, text: item.nom})

          })
        })

  },
  components:{
    Select2,
   /* Flatpickr,*/
  }
}
</script>