import ListeFrequence from '@/components/frequence/ListeFrequence';
import CreateFrequence from '@/components/frequence/CreateFrequence';
import EditFrequence from '@/components/frequence/EditFrequence';
export default [
    {
        path: '/frequences',
        name: 'listeFrequence',
        component: ListeFrequence
    },
    {
        path: '/frequences/create',
        name: 'createFrequence',
        component: CreateFrequence
    },
    {
        path: '/frequences/:id',
        name: 'editFrequence',
        component: EditFrequence
    }
]