<template>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 class="mb-sm-0">Moyen paiement</h4>
  
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
              <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Administration</router-link></li>
              <li class="breadcrumb-item active">Moyen paiement</li>
            </ol>
          </div>
  
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <form @submit.prevent="editMoyenPaiement">
            <div class="card card-animate">
  
              <div class="card-body">
                <div class="row text-start">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Nom</label>
                    <input v-model="moyenPaiement.nom" type="text" class="form-control" required>
                    <div class="invalid-feedback">Veuillez entrer le moyen de paiement</div>
                  </div>
                </div>
               
                <div class="row text-start mt-3">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Organisation</label>
                    <select v-model="moyenPaiement.type" type="text" class="form-control" required>
                      <!--<option :key="item.id" :value="item.id" v-for="item in listTypeRamification">{{item.nom}}</option>-->
                    </select>
                    <div class="invalid-feedback">Veuillez entrer l'organisation</div>
                  </div>
                </div>
                <div class="row text-start mt-3">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Description</label>
                    <input v-model="moyenPaiement.description" type="text" class="form-control" required>
                    <div class="invalid-feedback">Veuillez entrer la description</div>
                  </div>
                </div>
                <div class="row text-start mt-3">
                  <div class="col-sm-12">
                    <label for="firstName" class="form-label">Commission</label>
                    <input v-model="moyenPaiement.commission" type="text" class="form-control" required>
                    <div class="invalid-feedback">Veuillez entrer la description</div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <div class="form-check form-switch form-switch-success">
                      <input  v-model="moyenPaiement.enabled" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" >
                      Active ?
                    </div>
                  </div>
                </div>
  
              </div>
              <div class="card-footer">
                <div class="row">
                  <button type="submit" class="btn btn-primary">Enregistrer</button>
  
                </div>
              </div>
            </div>
  
          </form>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MoyenPaiement from "@/entity/MoyenPaiement";
  import Repository from '@/repository/MoyenPaiementRepository'
 /* import TypeRamificationRepository from '@/repository/TypeRamificationRepository'
  import EntiteRepository from '@/repository/EntiteRepository'*/
  
  export default {
    name: 'EditMoyenPaiementPage',
    data() {
      return {
        moyenPaiement: new MoyenPaiement(),
        repository: new Repository(),
        /*entiteRepository: new EntiteRepository(),
        typeRamificationRepo: new TypeRamificationRepository(),*/
        listTypeRamification: null,
        selectedEntite: null,
        listParent: null,
  
  
      }
    },
    computed: {
    },
    methods: {
      editMoyenPaiement(){
        this.repository.edit(this.moyenPaiement, this.$route.params.id);
        this.$router.push({ name: 'listeMoyenPaiement' })
      }
  
    },
    created() {
      this.repository.find(this.$route.params.id)
          .then(response => {
            this.moyenPaiement = response;
            //this.ramification.type = response.type.id;
            //this.ramification.parent = response.parent.id;
            //   console.log(this.listCollectivite);
          })
          .catch(error => {
            console.log(error);
          });
      /*this.typeRamificationRepo.search([{ colonne: '', operator: '', value: '' }], 'tr.nom', 'asc', 30, 1)
          .then(response => {
            this.listTypeRamification = response.data;
            //   console.log(this.listCollectivite);
          })
          .catch(error => {
            console.log(error);
          });
      this.repository.search([{ colonne: '', operator: '', value: '' }], 'ram.nom', 'asc', 30, 1)
          .then(response => {
            this.listParent = response.data;
            //   console.log(this.listCollectivite);
          })
          .catch(error => {
            console.log(error);
          });
      this.entiteRepository.search([{ colonne: '', operator: '', value: '' }], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            this.selectedEntite = response.data;
            this.ramification.entite = response.data.id;
            //   console.log(this.listCollectivite);
          })
          .catch(error => {
            console.log(error);
          });*/
  
  
    }
  }
  </script>