



<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Modification enrôlement</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Collectivité</router-link></li>
            <li class="breadcrumb-item active">Enrôlement modification</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <!--<div class="row">
    <div class="col-md-12">
      <h5 style="float:left">Contribuables</h5>
    </div>
  </div>-->

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <form @submit.prevent="modifier"> <!--@submit.prevent="editTypeEquipement"-->
          <div class="card">
            <div class="card-body">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs mb-3" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#home" role="tab" aria-selected="false">
                    Infos contribuable
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " data-bs-toggle="tab" href="#product1" role="tab" aria-selected="false">
                    Enrollements
                  </a>
                </li>

              </ul>
              <!-- Tab panes -->
              <div class="tab-content  text-muted">
                <div class="tab-pane active" id="home" role="tabpanel">
                  <div class="row text-start mb-3">
                    <div class="col-sm-12">
                      <label for="Name" class="form-label">Type</label>
                      <select v-model="contribuable.type" class="form-control form-select">
                        <option :value="type" v-for="type in types" :key="type">{{type}}</option>
                      </select>
                      <div class="invalid-feedback">Veuillez entrer </div>
                    </div>

                  </div>
                  <div class="row text-start mb-3">
                    <div v-if="contribuable.type==='Personne morale'" class="col-sm-12">
                      <label for="Name" class="form-label">Raison sociale</label>
                      <input type="text" class="form-control"  v-model="contribuable.raisonSociale"  />
                    </div>
                    <div class="col-sm-6">

                      <label for="Name" class="form-label">Nom</label>
                      <input type="text" class="form-control"  v-model="contribuable.nom"  />
                    </div>
                    <div class="col-sm-6">
                      <label for="Name" class="form-label">Prenom</label>
                      <input type="text" class="form-control"  v-model="contribuable.prenom"  />
                    </div>
                    <div class="col-sm-6">
                      <label for="Name" class="form-label">Age</label>
                      <input type="text" class="form-control"  v-model="contribuable.age"  />
                    </div>
                    <div class="col-sm-6">
                      <label for="Name" class="form-label">Lieu de naissance</label>
                      <input type="text" class="form-control"  v-model="contribuable.lieuNaissance"  />
                    </div>
                    <div class="col-sm-6">
                      <label for="Name" class="form-label">Télephone</label>
                      <input type="text" class="form-control"  v-model="contribuable.telephone"  />
                    </div>
                    <div class="col-sm-6">
                      <label for="Name" class="form-label">Moyen de paiement préferé</label>
                      <select v-model="contribuable.moyenPaiementPrefere" class="form-control form-select">
                        <option v-for="moyenPaiement in listMoyenPaiement" :value="moyenPaiement.id" :key="moyenPaiement">{{moyenPaiement.nom}}</option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                      <label for="Name" class="form-label">Type pièce d'identité</label>
                      <input type="text" class="form-control"  v-model="contribuable.typePieceIdentite"  />
                    </div>
                    <div class="col-sm-6">
                      <label for="Name" class="form-label">Numéro pièce d'identité</label>
                      <input type="text" class="form-control"  v-model="contribuable.numPieceIdentite"  />
                    </div>

                    <div class="col-sm-12">
                      <label for="Name" class="form-label">Ramification</label>
                      <select v-model="contribuable.ramification" class="form-control form-select">
                        <option  :key="item.id" :value="item.id" v-for="item in listRamifications">{{item.nom}}</option>
                      </select>
                      <div class="invalid-feedback">Veuillez entrer </div>

                    </div>
                    <div class="col-sm-12">
                      <label for="Name" class="form-label">Adresse</label>
                      <input type="text" class="form-control"  v-model="contribuable.adresse"  />
                    </div>

                  </div>

                </div>
                <div class="tab-pane " id="product1" role="tabpanel">
                  <button v-if="enrollements.length === 0" @click="addEnrollement" class="btn btn-success "><i class="bx bx-plus"></i> </button>

                  <div class="row text-start mb-3" v-for="(enrollement, index) in enrollements" :key="enrollement.id">
                    <div class="col-sm-5" v-if="enrollement.equipement != null">
                      <label for="Name" class="form-label">Equipement</label>
                      <Select2 @select="getPrecisionEquip(index)" @change="getPrecisionEquip(index)" :options="listTypeEquipement"  v-model="enrollement.equipement.type">
                      </Select2>
                      <div class="invalid-feedback">Veuillez entrer </div>
                    </div>
                    <div class="col-sm-5" v-if="enrollement.equipement != null">
                      <label for="Name" class="form-label">Precision</label>
                      <select v-model="enrollement.variation" class="form-control form-select">
                        <option v-for="variation in listPrecision[index]" :key="variation" :value="variation">{{variation}}</option>
                      </select>
                      <div class="invalid-feedback">Veuillez entrer </div>
                    </div>
                    <div class="col-sm-10" v-if="enrollement.natureActivite != null">
                      <label for="Name" class="form-label">Activité</label>
                      <Select2 class="form-control " :options="listNatureActivite.map(item => ({id: item.id, text: item.nom}))"  v-model="enrollement.natureActivite">
                      </Select2>


                      <div class="invalid-feedback">Veuillez entrer </div>
                    </div>
                    <div class="col-md-2">
                      <a @click="deleteEnrollement(index)" class="btn btn-danger mt-2"><i class="bx bx-trash"></i> </a>
                      <a @click="addEnrollement" class="btn btn-success mt-2 m-2"><i class="bx bx-plus"></i> </a>
                    </div>

                  </div>

                </div>

              </div>
            </div><!-- end card-body -->
            <div class="card-footer">
              <button class="btn btn-primary" type="submit">Enregistrer</button>
            </div>
          </div><!-- end card -->


        </form>

      </div>
    </div>
  </div>
</template>

<script>
import Taxe from "@/entity/Taxe";
import TypeRamificationRepository from '@/repository/TypeRamificationRepository'
import EntiteRepo from "@/repository/EntiteRepository";
import CategorieRepo from "@/repository/CategorieTaxeRepository";
import RamificationRepo from "@/repository/RamificationRepository";
import RepositoryTaxe from '@/repository/TaxeRepository'
import ContribualeRepo from "@/repository/ContribuableRepository";
import NatureActivite from "@/entity/NatureActivite";
import TypeEquipementRepository from "@/repository/TypeEquipementRepository";
import ApplicationRepository from "@/repository/ApplicationRepository";
import MoyenPaiementRepository from "@/repository/MoyenPaiementRepository";
//import Repository from '@/repository/NatureActiviteRepository'
import EquipementRepository from '@/repository/EquipementRepository';
import NatureActiviteRepository from '@/repository/NatureActiviteRepository';
import EnrollementRepository from '@/repository/EnrollementRepository';
import TaxeRepository from "@/repository/TaxeRepository";
import Select2 from "vue3-select2-component";
//import EnrollementRepository from "@/repository/EnrollementRepository";
export default {
  name: 'EditEnrolementPage',

  data() {
    return {
      user: null,
      enrollements: [],
      newEnrollement: [],
      contribuable: {},
      natureActiviteRepo: new NatureActiviteRepository(),
      taxe: new Taxe(),
      types: ['Personne physique', 'Personne morale'],
      choiceActivite: null,
      choiceEquipement: null,
      choiceCategorie: null,
      listPrecision: [],
      entite: null,
      applications: [],
      listCollectivite: null,
      moyenPaiementRepo: new MoyenPaiementRepository(),
      ramificationRepo: new RamificationRepo(),
      entiteRepo: new EntiteRepo(),
      taxeRepo: new TaxeRepository(),
      typeRamificationRepo: new TypeRamificationRepository(),
      appRepo: new ApplicationRepository(),
      categorieRepo: new CategorieRepo(),
      typeEquipementRepo: new TypeEquipementRepository(),
      listCategorieTaxes: null,
      listTypeEquipement: null,
      listMoyenPaiement: null,
      listNatureActivite: null,
      fields: [
        { libelle: '', tarif: '', annee: '' }
      ],
      fieldsMontant: [
        { tarif: '', annee: '' }
      ],
      repositoryTaxe: new RepositoryTaxe(),
      enrollementRepository: new EnrollementRepository(),
      repository: new ContribualeRepo(),
      natureActivite: new NatureActivite(),
      equipementRepository: new EquipementRepository(),
      listEquipements: null,
      listBaseTaxable: null,
      listRamifications: null


    }
  },
  computed: {

  },
  methods: {
    getPrecisionEquip(index) {
      this.listPrecision[index]=null;
      this.taxeRepo.search([{colonne: 'ent.id', operator: 'eq', value: this.entite}, {colonne:'te.id', operator: 'eq', value: this.enrollements[index].equipement.type}], 'entx.nom', 'asc', 30, 1)
          .then(taxeResponse => {
            this.listTaxe = taxeResponse.data;
            this.listPrecision[index] = this.listTaxe[0].applications.filter(app => app.ramification.id === this.contribuable.ramification && app.annee === new Date().getFullYear().toString())[0].precisione.map(ite => ite.libelle)
            console.log(this.listPrecision);
          })
    },
    addEnrollement(){
      this.enrollements.push({equipement:{}, natureActivite: {} });
    },
    deleteEnrollement(index){
      this.enrollements.splice(index, 1);
    },
    switchEquipement(){
      this.taxe.natureActivite = null;
    },
    switchActivite(){
      this.taxe.typeEquipement = null;
    },
    modifier() {
      //console.log(id);
     // console.log(this.contribuable);
      this.contribuable.createdBy = this.contribuable.createdBy.id;
      this.contribuable.statut='Corrigé';
      delete this.contribuable.transactions;
      delete this.contribuable.contribEnrollements;
      delete this.contribuable.contribTransactions;
      delete this.contribuable.equipements;
      delete this.contribuable.baseTaxables
      delete this.contribuable.correctedAt
      delete this.contribuable.correctedBy
      delete this.contribuable.validatedAt
      delete this.contribuable.validatedBy
      delete this.contribuable.echecs
      delete this.contribuable.nomMoyenPaiementPrefere
      delete this.contribuable.situation

      this.repository.edit(this.contribuable, this.contribuable.id)
          .then(contrib => {
            this.contribuable = contrib;
            this.contribuable.ramification = contrib.ramification.id;
            this.repository.correct(this.contribuable.id, this.user.id)
                .then(response => {

                  this.contribuable = response;
                  this.contribuable.ramification = response.ramification.id
                //  console.log(this.contribuable.ramification)

                  this.enrollements.forEach(enrollement => {

                    var equipement = {};
                    if (enrollement.equipement != null){
                      console.log(enrollement.equipement.type)

                      equipement.type = enrollement.equipement.type;
                      equipement.precisione = enrollement.variation;
                      equipement.createdBy = this.user.id;
                      equipement.ramification = this.contribuable.ramification;
                      this.equipementRepository.create(equipement)
                          .then(equip => {
                            var enr = {natureActivite: null};
                            enr.contribuable = this.contribuable.id;
                            enr.equipement = equip.id;
                            enr.createdBy = this.user.id;
                            enr.variation = enrollement.variation;
                            enr.natureActivite = null;

                            this.enrollementRepository.create(enr)
                            this.$router.push({'name': 'listEnrollement'});


                          });

                    }
                    if (enrollement.natureActivite!=null ){
                      var enr = {};
                      enr.contribuable = this.contribuable.id;
                      //console.log(enrollement.natureActivite)
                      enr.natureActivite = enrollement.natureActivite;
                      enr.createdBy = this.user.id;
                      this.enrollementRepository.create(enr);
                      this.$router.push({'name': 'listEnrollement'});

                    }
                  })


                })

          })


      /*this.enrollements.forEach((enrol) => {



      });*/



      //this.contribRepo.edit(this.newEnrolement, id );
      // console.log('ok');
    }
  },
  created() {
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
    this.typeEquipementRepo.search([{ colonne: '', operator: '', value: '' }], 'teq.nom', 'asc', 3000, 1)
        .then(response => {
          this.listTypeEquipement = [];
          response.data.forEach(item => {
            this.listTypeEquipement.push({id: item.id, text: item.nom})
          })
         // this.listTypeEquipement = response.data;
          // console.log(this.listTypeEquipement);
        })

    this.natureActiviteRepo.search([{ colonne: '', operator: '', value: '' }], 'na.nom', 'asc', 3000, 1)
        .then(response => {
          this.listNatureActivite = response.data;
          // console.log(this.listNatureActivite);
        })
    this.ramificationRepo.search([{ colonne: '', operator: '', value: ''  }], 'ram.nom', 'asc', 3000, 1)
        .then(response => {
          this.listRamifications = response.data;
          //le.log(this.listCollectivite);
        })
    this.moyenPaiementRepo.search([], 'mp.nom', 'asc', 3000, 1)
        .then(response => {
          this.listMoyenPaiement = response.data;
          //le.log(this.listCollectivite);
        })
    this.repository.find(this.$route.params.id)
        .then(response => {
          this.entite = response.ramification.entite.id
          this.contribuable = response;
          this.contribuable.ramification = response.ramification.id
          this.enrollements = [];
          //console.log(entite);
          this.contribuable.moyenPaiementPrefere = response.moyenPaiementPrefere.id
          //  this.enrollements = this.contribuable.contribEnrollements;
          this.contribuable.contribEnrollements.forEach((enrollement, index) => {//, index
            if (enrollement.equipement != null){
              this.enrollements.push({equipement: {type: enrollement.equipement.type.id}, variation: enrollement.variation, natureActivite:null})
            }
            if (enrollement.natureActivite != null)
              this.enrollements.push({natureActivite:  enrollement.natureActivite.id, equipement:null})
              //console.log(this.enrollements)
            if (enrollement.equipement != null){
              this.taxeRepo.search([{colonne: 'ent.id', operator: 'eq', value: this.entite}, {colonne:'te.id', operator: 'eq', value: this.enrollements[index].equipement.type }], 'entx.nom', 'asc', 30, 1)
                  .then(taxeResponse => {
                    this.listTaxe = taxeResponse.data;
                    this.listPrecision[index] = this.listTaxe[0].applications.filter(app => app.ramification.id === this.contribuable.ramification && app.annee === new Date().getFullYear().toString())[0].precisione.map(ite => ite.libelle)

                    console.log(this.listPrecision);
                  })
            }
            //this.enrollements[index].natureActivite = enrollement.natureActivite.id;
          })
          console.log(this.enrollements)
        })


  },
  components: {
    Select2
  }
}
</script>


