<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0" v-if="selectedEntite != null">{{ selectedEntite.nom }} > Tous les contribuables</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home' }">Tableau de bord</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home' }">Statistiques</router-link>
            </li>
            <li class="breadcrumb-item active">Recensement par agent</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          <!--
          <span v-if="entite !=null">{{ entite.text }}</span>
          <span v-if="departement !=null"> > {{ departement.text }}</span>
          <span v-if="quartier !=null"> > {{ quartier.text }}</span>
          <span v-if="zone !=null"> > {{ zone.text }}</span>-->
        </h4>
        <div class="page-title-right">
          <div>
            <div style="display: inline">


              <button @click="filterByDay" class="btn btn-primary ">
                Aujourd'hui
              </button>
              <button @click="filterByWeek" class="btn btn-soft-secondary ">
                Cette semaine
              </button>
              <button @click="filterByMonth" class="btn btn-soft-secondary ">
                Ce mois
              </button>
              <button @click="filterByYear" class="btn btn-soft-secondary ">
                Cette année
              </button>
              <div class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical align-middle"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                  <li>
                    <button class="dropdown-item" @click="filterByYesterday">Hier</button>
                  </li>
                  <li>
                    <button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button>
                  </li>
                  <li>
                    <button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button>
                  </li>
                  <li>
                    <Flatpickr
                        @change="onDateChange"
                        v-model="daterange"
                        :config="config"
                        class="form-control "
                        placeholder="Select date"/>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="row">
          <!--<div class="col-md-6">
            <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
          </div>-->
          <div class="col-md-6">
            <Select2 :placeholder="agent" @select="selectAgent($event)" @change="changeAgent($event)"
                     class="form-control form-select" v-model="agentId"
                     :options="listUtilisateurs.map(item => ({id: item.id, text: item.fullName}))"/>
            <!--:placeholder="'Tous les departements'"  v-model="selectedDepartement"-->
          </div>
        </div>
        <!--<div class="row mt-2">
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectedSecteur($event)" @change="changeSecteur($event)"  />
          </div>
        </div>-->
        <br>

        <div v-if="listeAgent === false" class="row mt-3">
          <h3 style="float: left">{{ this.periode }}</h3>
          <div class="col-xl-12">
            <div class="card crm-widget">
              <div class="card-body p-0">
                <div class="row row-cols-xxl-4">
                  <div class="col text-start bg-warning">
                    <div class="py-4 px-3">
                      <h5 class=" text-uppercase fs-13">Contribuable(s) enrôlé(s) </h5>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <i class="ri-user-3-line display-6 text-white"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h2>
                            <router-link
                                :to="{name: 'listeContribuables', query: {'agent': this.agentId,  from: this.from, to: this.to} }">
                              {{ nombreTotalEnrol }}
                            </router-link>
                          </h2>

                          <!--<h2 class="mt-4 ff-secondary fw-semibold"><span class="counter-value" :data-target="report.contribuables"><router-link :to="{name : 'listeContribuables', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(this.report.contribuables) }}</router-link></span></h2>-->
                        </div>
                      </div>
                    </div>
                  </div><!-- end col -->
                  <!-- <div class="col text-start bg-info">
                     <div class="py-4 px-3 text-white">
                       <h5 class="text-uppercase fs-13 text-white">Assiète </h5>
                       <div class="d-flex align-items-center">
                         <div class="flex-shrink-0">
                           <i class="ri-money-dollar-box-line display-6 text-white"></i>
                         </div>
                         <div class="flex-grow-1 ms-3">
                           <h2 class="mb-0"><span class="counter-value text-white" data-target="{{dashboard.prevu}}">{{ formattedNumber(dashboard.prevu) }}</span></h2>
                         </div>
                       </div>
                     </div>
                   </div>--><!-- end col -->
                  <!--                  <div class="col text-start bg-success">
                                      <div class="py-4 px-3">
                                        <h5 class="text-uppercase fs-13 text-white">Nombre de Validations <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                        <div class="d-flex align-items-center">
                                          <div class="flex-shrink-0">
                                            <i class="ri-money-dollar-box-line display-6 text-white"></i>
                                          </div>
                                          <div class="flex-grow-1 ms-3">
                                            <h2><router-link :to="{name: 'listeContribuables', query: {'agent': this.agentId,  from: this.from, to: this.to, statut: 'Validé'} }">{{nombreValidation}}</router-link></h2>
                                          &lt;!&ndash;<h2 class="mt-4 ff-secondary fw-semibold"><span style="color: #fff" class="counter-value" :data-target="Number(report.valide)"><router-link class="text-white" :to="{name : 'listeContribuables', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(report.valide) }}</router-link></span></h2>&ndash;&gt;
                                          </div>
                                        </div>
                                      </div>
                                    </div>&lt;!&ndash; end col &ndash;&gt;-->
                  <div class="col text-start bg-danger" @click="hideMenu"
                       v-if="user.groupe.nom === 'Admin NTA' || user.groupe.nom === 'Super Admin'">
                    <div class="py-4 px-3">
                      <h5 class=" text-uppercase fs-13 text-white">Correction </h5>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <i class="ri-user-3-line display-6 text-white"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h2>
                            <router-link
                                :to="{name: 'listeContribuables', query: {'agent': this.agentId,  from: this.from, to: this.to, statut: 'Corrigé'} }">
                              {{ nombreCorrige }}
                            </router-link>
                          </h2>
                          <!--<h2 class="mt-4 ff-secondary fw-semibold text-white"><span class="counter-value" data-target="3"><router-link style="color: #fff" :to="{name : 'listeTransactions', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(report.totalNonValide )}}</router-link></span></h2>-->
                        </div>
                      </div>
                    </div>
                  </div><!-- end col -->
                  <div class="col text-start bg-success">
                    <div class="py-4 px-3">
                      <h5 class="text-uppercase fs-13 text-white">Nombre de transactions <i
                          class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <i class="ri-money-dollar-box-line display-6 text-white"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h2>
                            <router-link
                                :to="{name: 'listeTransactions', query: {'agent': this.agentId,  from: this.from, to: this.to, statut: 'Confirmé'} }">
                              <td>{{ nombreTransaction }}</td>
                            </router-link>
                          </h2>
                          <!--<h2 class="mt-4 ff-secondary fw-semibold"><span style="color: #fff" class="counter-value" :data-target="Number(report.valide)"><router-link class="text-white" :to="{name : 'listeContribuables', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(report.valide) }}</router-link></span></h2>-->
                        </div>
                      </div>
                    </div>
                  </div><!-- end col -->
                  <div class="col text-start bg-primary">
                    <div class="py-4 px-3">
                      <h5 class=" text-uppercase fs-13 text-white">Somme collectée</h5>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <i class="ri-money-dollar-box-line display-6 text-white"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <router-link
                              :to="{name: 'listeTransactions', query: {'agent': this.agentId,  from: this.from, to: this.to, statut: 'Confirmé'} }">
                            <h2 class="text-white">{{ sommeCollectee.toLocaleString('fr-FR') }}</h2>
                          </router-link>
<!--                          <h2 class="text-white">{{ sommeCollectee.toLocaleString('fr-FR') }}</h2>-->
                          <!--<h2 class="mt-4 ff-secondary fw-semibold text-white"><span class="counter-value" data-target="3"><router-link style="color: #fff" :to="{name : 'listeTransactions', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(report.totalNonValide )}}</router-link></span></h2>-->
                        </div>
                      </div>
                    </div>
                  </div><!-- end col -->
                </div><!-- end row -->
                <div class="row row-cols-xxl-4">
                  <!--
                  <div class="col text-start bg-warning text-white ">
                    <div class="py-4 px-3">

                  <div class="d-flex align-items-center">

                    <div class="flex-shrink-0">
                      <i class="ri-user-3-line display-6 "></i>
                    </div>
                    <div class="flex-grow-1 ms-3">


                    </div>
                  </div>
                </div>
              </div>

              <div class="col text-start bg-success">
                <div class="py-4 px-3">

                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <i class="ri-money-dollar-box-line display-6 text-white"></i>
                    </div>
                    <div class="flex-grow-1 ms-3">

                    </div>
                  </div>
                </div>
              </div>
              <div class="col text-start bg-danger">

              </div>

                  -->
                </div><!-- end row -->
              </div><!-- end card body -->
            </div><!-- end card -->
          </div><!-- end col -->
        </div>

        <br> <br>

        <div class="row">
          <div class="card card-animate mt-3">
            <div class="card-body">
              <div v-if="listeAgent === false" class="row">
                <div class="table-responsive">

                  <table id="element-to-convert" class="table text-start mb-0 table-bordered text-center">
                    <thead class="table-light">
                    <tr class="text-center">
                      <th scope="col" class="bg-success">
                        <div class="form-check">
                          <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox"
                                 value="" id="responsivetableCheck">
                          <label class="form-check-label" for="responsivetableCheck"></label>
                        </div>
                      </th>
                      <th class="bg-success" scope="col">#</th>
                      <th class="bg-success" scope="col">Date</th>
                      <th class="bg-success" scope="col">Nombre d'enrôlement</th>
<!--                      <th class="bg-success" scope="col">Nombre validation</th>-->
                      <th class="bg-success" scope="col" @click="hideMenu"
                          v-if="user.groupe.nom === 'Admin NTA' || user.groupe.nom === 'Super Admin'">Correction</th>
                      <th class="bg-success"></th>

                      <!--<th class="bg-success" scope="col" >Base taxable</th>
                      <th class="bg-success" colspan="3" scope="col">Situations fiscale</th>
                      <th class="bg-success" colspan="2" scope="col">Agent</th>
                      <th class="bg-success"></th>-->
                    </tr>
                    <!--<tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>

                      <th>Nom/Raison sociale</th>
                      <th>Emplacement</th>
                      <th>Statut</th>
                      <th></th>
                      <th>Prevu</th>
                      <th>Payer</th>
                      <th>Restant</th>
                      <th>Pseudo</th>
                      <th>Nom</th>
                      <th></th>

                    </tr>-->
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in stats " :key="item.id">
                      <td><input class="form-check-input" type="checkbox" v-model="selectedItems"
                                 v-bind:value="item.id"/></td>
                      <td>{{ index + 1 }}</td>
                      <td v-if="item.date.length>2">{{ item.date }}</td>
                      <td v-else>{{ convertIntToMonth(item.date )}}</td>
                      <td>{{ item.total }}</td>
<!--                      <td>{{ item.valide }}</td>-->
                      <td @click="hideMenu"
                          v-if="user.groupe.nom === 'Admin NTA' || user.groupe.nom === 'Super Admin'">{{ item.corrige }}</td>
                      <td>
                        <div class="dropdown">
                          <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                             aria-expanded="false">
                            <i class="ri-more-2-fill"></i>
                          </a>

                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <!--<li><router-link :to="{name: 'ficheContribuables', params: { id: item.id }}" class="dropdown-item"><i class="ri-file-2-fill r-2x"></i> Fiche contribuable</router-link></li>
                            <li v-if="user.groupe.nom === 'Super Admin'"><a  @click="deleteNatureActivite(item.id)" class="dropdown-item" >Supprimer</a></li>
                            <li ><a  @click="edit(item.id)" class="dropdown-item" ><i class="las la-edit"></i>Modifier</a></li>
                            <li ><a  @click="validate(item.id)" class="dropdown-item" ><i class="las la-check-double"></i>  Valider</a></li>-->
                          </ul>
                        </div>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                  <!-- end table -->
                </div>
              </div>
              <div v-else class="row">
                <div class="table-responsive">

                  <table id="element-to-convert" class="table text-start mb-0 table-bordered text-center">
                    <thead class="table-light">
                    <tr class="text-center">
                      <th scope="col" class="bg-success">
                        <div class="form-check">
                          <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox"
                                 value="" id="responsivetableCheck">
                          <label class="form-check-label" for="responsivetableCheck"></label>
                        </div>
                      </th>
                      <th class="bg-success" scope="col">#</th>
                      <th class="bg-success" scope="col">Date</th>
                      <th class="bg-success" scope="col">Agent</th>
                      <th class="bg-success" scope="col">Nombre enrôlements</th>
                      <th class="bg-success" scope="col">Total transaction</th>
                      <th class="bg-success"></th>

                      <!--<th class="bg-success" scope="col" >Base taxable</th>
                      <th class="bg-success" colspan="3" scope="col">Situations fiscale</th>
                      <th class="bg-success" colspan="2" scope="col">Agent</th>
                      <th class="bg-success"></th>-->
                    </tr>
                    <!--<tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>

                      <th>Nom/Raison sociale</th>
                      <th>Emplacement</th>
                      <th>Statut</th>
                      <th></th>
                      <th>Prevu</th>
                      <th>Payer</th>
                      <th>Restant</th>
                      <th>Pseudo</th>
                      <th>Nom</th>
                      <th></th>

                    </tr>-->
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in listUtilisateurs " :key="item.id">
                      <td></td>
                      <!--<input class="form-check-input" type="checkbox" v-model="selectedItems" v-bind:value="item.id" />-->
                      <td>{{ index + 1 }}</td>
                      <td></td>
                      <td>{{ item.fullName }}</td>
                      <td>{{ item.situations.nbreEnrollements }}</td>
                      <td>{{ item.situations.totalTransactions.toLocaleString('fr-FR') }}</td>
                      <td>
                        <div class="dropdown">
                          <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                             aria-expanded="false">
                            <i class="ri-more-2-fill"></i>
                          </a>

                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <!--<li><router-link :to="{name: 'ficheContribuables', params: { id: item.id }}" class="dropdown-item"><i class="ri-file-2-fill r-2x"></i> Fiche contribuable</router-link></li>
                            <li v-if="user.groupe.nom === 'Super Admin'"><a  @click="deleteNatureActivite(item.id)" class="dropdown-item" >Supprimer</a></li>
                            <li ><a  @click="edit(item.id)" class="dropdown-item" ><i class="las la-edit"></i>Modifier</a></li>
                            <li ><a  @click="validate(item.id)" class="dropdown-item" ><i class="las la-check-double"></i>  Valider</a></li>-->
                          </ul>
                        </div>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                  <!-- end table -->
                </div>
              </div>
              <!-- end table responsive -->
            </div>
          </div>
        </div>
          <br>
          <div id="chart" v-if="listeAgent === false">
              <apexchart type="line" height="350" :options="chartOptions()" :series="series()"></apexchart>
          </div>
          <div style="width: 100%; height: 15px; background-color: white"></div>
          <br>
      </div>
    </div>
  </div>
</template>

<script>
//import Utilisateur from "@/entity/User";
import Repository from '@/repository/UserRepository'
import Respository2 from '@/repository/TransactionRepository';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
//import Repository from '@/repository/ContribuableRepository'
import moment from "moment";
import html2pdf from "html2pdf.js"
import Select2 from 'vue3-select2-component';

import store from "@/store";
import Utilisateur from '@/repository/AgentRepository';
import RamificationRepository from "@/repository/RamificationRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import fr from "flatpickr/dist/l10n/fr";

export default {
  name: 'listCategorieActivitePage',
  data() {
    return {

      /*series: [{
        name: 'Nombre enrôlement(s)',
        type: 'column',
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
      }, {
        name: 'Nombre transactions(s)',
        type: 'column',
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
      },
        {
          name: 'Somme collectée',
          type: 'column',
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
        }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Statistiques agents'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
          type: 'datetime'
        },
        yaxis: [{
          title: {
            text: 'Agents',
          },

        }, {
          opposite: true,
          title: {
            text: 'jjfjf'
          }
        }]
      },
*/


      listCollectivites: [],
      ids: [],
      ms: [],
      listeAgent: true,
      agentId: null,
      listDepartements: [],
      listQuartiers: [],
      listZones: [],
      daterange: "",
      nombreTotalEnrol: 0,
      nombreValidation: 0,
      nombreCorrige: 0,
      nombreTransaction:0,
      sommeCollectee: 0,
      //daterange: null,
      listSecteurs: [],
      entite: null,
      selectedAnnee: null,
      departement: null,
      quartier: null,
      zone: null,
      qt: [],
      utilisateurRepo: new Utilisateur(),
      secteur: null,
      isLoading: false,
      fullPage: true,
      user: null,
      query: [],
      from: null,
      to: null,
      utilisateur: new Utilisateur(),
      repository: new Repository(),
      repositoryTr: new Respository2(),
      orderBy: 'user.nom',
      sortOrder: 'asc',
      itemsPerPage: 100,
      selectedEntite: null,
      allSelected: false,
      periode: '',
      selectedItems: [],
      listContribuables: null,
      listUtilisateurs: [],
      stats: [],
      transacAgent: [],
      totalPages: 1,
      currentPage: 1,
      loading: false,
      ramificationRepo: new RamificationRepository(),
      entiteRepo: new EntiteRepository(),
      fields: [
        //{ colonne: '', operator: '', value: '' }
      ]

    }
  },
  computed: {
    config() {
      //var current_month = 12 - new Date().getMonth();
      return {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null) ? [this.selectedAnnee.text + "-" + new Date().getMonth() + "-01", this.selectedAnnee.text + "-12-" + new Date().getDay()] : [new Date().getFullYear() + '-' + new Date().getMonth() + "-01", new Date().getFullYear() + '-' + new Date().getFullYear() + '-' + new Date().getMonth() + "-31"],
      }

    },
    formatDate(value) {
      return moment(String(value)).format('DD/MM/YYYY')
    },
    pages() {
      let pages = Array.from({length: this.totalPages}, (_, i) => i + 1)
      let currentPage = this.currentPage
      let visiblePages = 5
      let start = currentPage - Math.floor(visiblePages / 2)
      let end = currentPage + Math.floor(visiblePages / 2)
      if (start < 1) {
        end += 1 - start
        start = 1
      }
      if (end > this.totalPages) {
        start -= end - this.totalPages
        end = this.totalPages
      }
      if (start > 1) {
        pages.splice(0, start - 1, '...')
      }
      if (end < this.totalPages) {
        pages.splice(end, this.totalPages - end, '...')
      }
      return pages.slice(start - 1, end)
    },

  },
  methods: {
    hideMenu() {
      this.hideMenuCol = false;
    },
    series() {
      return [
        {
          name: 'Enrolements',
          type: 'column',
          data: this.stats.map(item => item.total)
        },
        /*{
          name: 'Corrigé',
          type: 'column',
          data: this.stats.map(item => item.corrige)
        },*/
        {
          name: 'Nombre de transaction',
          type: 'column',
          data: this.stats.map(item => item.nbreTransaction)
        },
      ]

    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Enrollement par agent/'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: this.chartLabel(),
        xaxis: {
          type: 'date'
        },
        yaxis: [{
          title: {
            text: 'Enrôlements',
          },

        }]
      }
    },
    convertIntToMonth(month){
      return moment(
          month,           // Desired month
          'MM'            // Tells MomentJs the number is a reference to month
      ).format('MMMM')
    },

    chartLabel() {
      if (this.stats != null) {
        return this.stats.map(item => (item.date.length>2)?moment(item.date).format('DD-MM-YYYY'):moment(
            item.date,           // Desired month
            'MM'            // Tells MomentJs the number is a reference to month
        ).format('MMMM'))
      }
      return [];
    },

    selectEntite(val) {
      this.entite = val;
      this.isLoading = true;
      //  this.selectedEntite = val;
      this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {
        colonne: 'p.id',
        operator: 'is null',
        value: ''
      }], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom});
              this.fillTable();
            })
          });
    },
    changeEntite(val) {
      this.entite = val;
      this.selectedEntite = val;
    },
    selectAgent(val) {
      this.agentId = val.id
      this.fillTable()
    },
    changeAgent(val) {
      this.agentId = val.id;
      this.fillTable()
    },
    changeDepartement(val) {
      this.selectedDepartement = val;
    },
    async selectDepartement(val) {
      this.isLoading = true;
      this.departement = val;
      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})

            })
            this.fillTable();

          })
    },
    changeQuartier(val) {
      this.selectedQuartier = val;
    },
    selectQuartier(val) {
      this.isLoading = true;
      this.quartier = val;
      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
            })
            this.fillTable();
          })


    },
    contribuables() {
      return this.listContribuables.filter(item => item.statut = 'Validé');
    },

    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        html2canvas: {scale: 2},
        pagebreak: {mode: ['avoid-all', 'css', 'legacy']},
        filename: "liste-contribuables.pdf",
        jsPDF: {unit: 'mm', format: 'a0', orientation: 'landscape'}
      });
    },
    changeZone(val) {
      this.selectedZone = val;
    },
    selectZone(val) {
      this.isLoading = true;
      this.zone = val;
      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
            })
            this.fillTable();
          })

    },
    onDateChange() {
      this.refresh();
    },

    /*refresh(){
      if (this.entite === null && (this.departement === null && this.quartier === null && this.zone === null && this.secteur === null))
      {
        this.dashboardRepo.dashboardByEntite(this.ids, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0];
            });
        this.dashboardRepo.dashboardDetailsByEntite(this.ids, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            });
      }
      if (this.entite != null && (this.departement === null && this.quartier === null && this.zone === null && this.secteur === null))
      {
        this.dashboardRepo.dashboardByEntite(this.entite.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0];
            });
        this.dashboardRepo.dashboardDetailsByEntite(this.entite.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            });
      }
      if (this.departement != null && (this.quartier === null && this.zone === null && this.secteur === null))
      {
        this.dashboardRepo.dashboardDetailsByRamification(this.departement.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            })
        this.dashboardRepo.dashboardByRamification(this.departement.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0]
              this.isLoading=false;
            })
      }
      if (this.quartier != null && (this.zone === null && this.secteur === null))
      {
        this.dashboardRepo.dashboardDetailsByRamification(this.quartier.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            })
        this.dashboardRepo.dashboardByRamification(this.quartier.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0]
              this.isLoading=false;
            })
      }
      if (this.zone != null && this.secteur === null)
      {
        this.dashboardRepo.dashboardDetailsByRamification(this.zone.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            })
        this.dashboardRepo.dashboardByRamification(this.zone.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0]
              this.isLoading=false;
            })
      }
      if (this.secteur != null)
      {
        this.dashboardRepo.dashboardDetailsByRamification(this.secteur.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.tableau = response
            })
        this.dashboardRepo.dashboardByRamification(this.secteur.id, this.daterange, this.selectedAnnee.text)
            .then(response => {
              this.dashboard = response[0]
              this.isLoading=false;
            })
      }
    },*/
    filterByDay() {
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
      //console.log(this.agentId);
      this.fillTable();
      this.periode = "Aujourd'hui";
      this.listeAgent = false;
      //this.refresh();
    },
    filterByYesterday() {
      this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
      this.fillTable();
      this.periode = "Hier";
      this.listeAgent = false,
          //this.refresh();
          console.log(this.daterange)
    },
    filterByLastWeek() {
      this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
      this.fillTable();
      this.periode = "Semaine dernière";
      this.listeAgent = false;
      //this.refresh();
      console.log(this.daterange)
    },
    filterByLastMonth() {
      this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      this.fillTable();
      this.listeAgent = false;
      this.periode = "Mois dernier";
      //this.refresh();
      console.log(this.daterange)
    },
    filterByLastYear() {
      this.daterange = moment().subtract(1, 'years').startOf('year').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'years').endOf('year').format("YYYY-MM-DD");
      this.fillTable();
      this.periode = "Année dernière";
      this.listeAgent = false;

      // this.refresh();
      console.log(this.daterange)
    },
    filterByWeek() {
      this.daterange = moment().startOf('week').format("YYYY-MM-DD") + ' au ' + moment().endOf('week').format("YYYY-MM-DD");
      this.fillTable();
      this.periode = "Cette semaine";
      this.listeAgent = false;
      //console.log(this.daterange);
      //this.refresh();
    },
    filterByMonth() {
      this.daterange = moment().startOf('month').format("YYYY-MM-DD") + ' au ' + moment().endOf('month').format("YYYY-MM-DD");
      console.log(this.daterange);
      this.fillTable();
      this.periode = "Ce mois";
      this.listeAgent = false;
      //this.refresh();
    },
    filterByYear() {
      this.daterange = moment().startOf('year').format("YYYY-MM-DD") + ' au ' + moment().endOf('year').format("YYYY-MM-DD");
      //console.log(this.daterange)
      this.fillTable();
      this.periode = "Cette année";
      this.listeAgent = false;
    },
    deleteNatureActivite(id) {
      if (confirm("Etes-vous sûr de supprimer cet élément ?")) {
        this.loading = true;
        this.repository.supprimer(id);
        this.fillTable();
        this.loading = false;
      }
    },
    bulkDelete() {
      if (confirm("Etes vous sûr de vouloir supprimer cet élément ?")) {
        this.loading = true;
        this.selectedItems.forEach(element => {
          this.repository.supprimer(element);
          //console.log(element);
        })
        this.loading = false;
        this.fillTable();
      }

    },
    selectAll() {
      if (this.allSelected === false) {
        this.allSelected = true;
        this.selectedItems = this.listContribuables.map(type => type.id);
        //  console.log(this.selectedItems);
      } else {
        this.allSelected = false;
        this.selectedItems = [];

        // console.log(this.selectedItems);

      }

      //console.log(this.allSelected)
    },
    goToPage(page) {
      this.currentPage = page;
      this.$router.replace({name: "listeContribuables", query: {q1: "q1"}})
      this.fillTable();
    },
    addFields() {
      this.fields.push({colonne: '', operator: '', value: ''});
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },

    handleItemPerPage(event) {
      this.itemsPerPage = event.target.value;
      //   console.log('total de page ' + this.pages)
      // console.log(this.itemsPerPage)
      this.currentPage = 1;
      this.$router.replace({
        name: "listeContribuables",
        query: {itemsPerPage: event.target.value, orderBy: this.orderBy}
      })

      this.fillTable();


    },
    handleSearch() {
      //console.log(this.fields);
      this.fillTable();
    },
    handleOrderBy(event) {
      this.$router.replace({name: "listeContribuables", query: {orderBy: event.target.value}})

      this.orderBy = event.target.value;
      this.fillTable();

      //console.log(this.orderBy)

    },
    handleSortOrder(event) {
      this.sortOrder = event.target.value;
      // console.log(this.sortOrder)
      this.fillTable();

    },
    async fillTable() {
      //this.isLoading = true;
      this.from = this.daterange.includes('au') ? this.daterange.split('au')[0] : this.daterange;
      this.to = this.daterange.includes('au') ? this.daterange.split('au')[1] : this.daterange;


      await this.repository.findOne("/v2/statistiques/enrollement/par-agent?agent=" + this.agentId + "&from=" + this.from + "&to=" + this.to)
          .then(response => {
            this.stats = response;
            this.isLoading = false;
            this.nombreTotalEnrol = this.stats.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
            this.nombreValidation = this.stats.reduce((accumulator, currentValue) => accumulator += Number(currentValue.valide ?? 0), 0);
            this.nombreCorrige = this.stats.reduce((accumulator, currentValue) => accumulator += Number(currentValue.corrige ?? 0), 0);
            this.nombreTransaction = this.stats.reduce((accumulator, currentValue) => accumulator + currentValue.nbreTransaction, 0);
          });
      await this.repositoryTr.findOne("/v2/statistiques/transactions/par-agent?agent=" + this.agentId + "&from=" + this.from + "&to=" + this.to)
          .then(response => {
            this.transacAgent = response;
            this.sommeCollectee = this.transacAgent.reduce((accumulator, currentValue) => accumulator += Number(currentValue.total ?? 0), 0);

          })
    },

  },
  created() {
    this.selectedEntite = store.state.selectedCollectivite;
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;

    //this.fields.push({colonne: 'contrib.statut', operator: 'eq', value: 'Validé'})
    this.query = Object.values(this.$route.query);
    //console.log(this.query)
    this.entite = {id: this.query[0]};
    this.departement = {id: this.query[1]};
    this.quartier = {id: this.query[2]};
    this.zone = {id: this.query[3]};
    this.secteur = {id: this.query[4]};
    if (this.daterange !== '')
      this.daterange = this.query[5];
    else
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");


    // console.log(this.daterange)

    this.utilisateurRepo.search([{colonne: '', operator: '', value: ''}], 'user.nom', 'asc', 500, 1)
        .then(response => {
          this.listUtilisateurs = response.data.sort(function (a, b) {
            return b.situations.totalTransactions - a.situations.totalTransactions
          });
          console.log(this.listUtilisateurs)
        })
    this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.ids = [];
          this.ids = response.data.map(item => item.id);
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom});
            //this.fillTable();
          })
        })
  },
  components: {
    Loading,
    Select2
  }
}
</script>