import ListePays from '@/components/pays/ListePays';
import CreatePays from '@/components/pays/CreatePays';
import EditPays from '@/components/pays/EditPays';
export default [
    {
        path: '/pays',
        name: 'pays',
        component: ListePays
    },
    {
        path: '/pays/create',
        name: 'createPays',
        component: CreatePays
    },
    {
        path: '/pays/:id',
        name: 'editPays',
        component: EditPays
    }
]