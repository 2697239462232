import ListeAutreOrganisation from '@/components/autreOrganisation/ListeAutreOrganisation';
import CreateAutreOrganisation from '@/components/autreOrganisation/CreateAutreOrganisation';

export default [
    {
        path: '/autre_organisations',
        name: 'listeAutreOrganisation',
        component: ListeAutreOrganisation
    },
    {
        path: '/autre_organisations/create',
        name: 'createAutreOrganisation',
        component: CreateAutreOrganisation
    }
]