import API from "@/helpers/Api";
import store from "@/store";
import User from "@/entity/User";

export default class TaxeRepository extends API {

    user = new User();


    orderByOptions= [
        { text: 'id', value: 'id', name: 'entx.id' },
        { text: 'taxe', value: 'taxe', name: 'entx.taxe' },
        { text: 'collectivité', value: 'collectivite', name: 'ent.id' },
        { text: 'Activite', value: 'activite', name: 'na.id' },
        { text: 'Equipement', value: 'equipement', name: 'te.id' },
        { text: 'enabled', value: 'enabled', name: 'entx.enabled' },
    ];

    create(data){
        //console.log(data);
        const entite_taxes = this.post('/v2/entite_taxes', data)
        return entite_taxes;
    }
    edit(data, id){
        const entite_taxes = this.patch('/v2/entite_taxes/'+id, data)
        return entite_taxes;
    }
    find(id){
        const entite_taxes = this.findOne('/v2/entite_taxes/'+id)
        return entite_taxes;
    }
    supprimer(id){
        const entite_taxes = this.delete('/v2/entite_taxes/'+id)
        return entite_taxes;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {
        this.user = store.state.user

        let ids = [];
        if (this.user.groupe.nom === 'Super Admin'){
            const entite_taxes = this.listing('/v2/entite_taxes', fields, orderBy, sortOrder, itemPerPage, currentPage)
            //console.log(ramifications);
            return entite_taxes;
        }else{
            if (this.user.entites.length > 0){
                this.user.entites.forEach(item => {
                    ids.push(item.id)
                })
                const entite_taxes = this.listing('/v2/entite_taxes?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(entite_taxes);
                return entite_taxes;
            }
            if (this.user.ramifications.length > 0){
                this.user.ramifications.forEach(item => {
                    ids.push(item.entite.id)
                })
                const entite_taxes = this.listing('/v2/entite_taxes?filter[ent.id][type]=in&filter[ent.id][x]='+ids, fields, orderBy, sortOrder, itemPerPage, currentPage)
                //console.log(entite_taxes);
                return entite_taxes;
            }
        }
    }


}