import API from "@/helpers/Api";
import {trim} from "core-js/internals/string-trim";

export default class ReleveRepository extends API {

    paye_equipement(param) {
        const paye = this.post('/v2/equipement/releve_paye?entite=' + param.entite + '&ramification=' + param.ramification + '&equipement=' + param.equipement + '&generatePar=' + param.user + '&from=' + trim(param.from) + '&to=' + trim(param.to), []);
        return paye;
    }
    impaye_equipement(param) {
        const impaye = this.post('/v2/equipement/releve_impaye?entite=' + param.entite + '&ramification=' + param.ramification + '&equipement=' + param.equipement + '&generatePar=' + param.user + '&from=' + trim(param.from) + '&to=' + trim(param.to), []);
        return impaye;
    }

    liste_equipement(param) {
        const liste_equipement = this.post('/v2/equipement/releve_liste?entite=' + param.entite + '&ramification=' + param.ramification + '&equipement=' + param.equipement + '&generatePar=' + param.user + '&from=' + trim(param.from) + '&to=' + trim(param.to), []);
        return liste_equipement;
    }
    facture(param) {
        const liste_equipement = this.post('/v2/transactions/report?entite=' + param.entite + '&ramification=' + param.ramification + '&generate=true'  + '&generatePar=' + param.user + '&from=' + trim(param.from) + '&to=' + trim(param.to), []);
        return liste_equipement;
    }

    impaye(param) {
        const impaye = this.post('/v2/contribuables/releve-impaye?entite=' + param.entite + '&ramification=' + param.ramification + '&activite=' + param.activite + '&equipement=' + param.equipement + '&from=' + trim(param.from) + '&to=' + trim(param.to), []);
        return impaye;
    }

    moyenPaiement(param) {
        const moyenPaiement = this.post('/v2/contribuables/releve-moyen_paiement?entite=' + param.entite + '&ramification=' + param.ramification + '&moyenPaiement=' + param.moyenPaiement + '&from=' + trim(param.from) + '&to=' + trim(param.to), []);
        return moyenPaiement;
    }

    tdrl(param) {
        const moyenPaiement = this.post('/v2/contribuables/tdrl?entite=' + param.entite + '&ramification=' + param.ramification + '&from=' + trim(param.from) + '&to=' + trim(param.to), []);
        return moyenPaiement;
    }


}