import ListeContribuable from "@/components/contribuable/ListeContribuable";
import FicheContribuable from "@/components/contribuable/FicheContribuable";
import EditContribuable from "@/components/contribuable/EditContribuable";
import ChangeSecteur from "@/components/contribuable/ChangeSecteur";
import Remboursement from "@/components/contribuable/Remboursement";
import FicheIndividuelle from "@/components/contribuable/FicheIndividuelle";
import ContribuableDetails from "@/components/contribuable/ContribuableDetails";


export default [

    {
        path: '/contribuables',
        name: 'listeContribuables',
        component: ListeContribuable
    },
    {
        path: '/contribuables-details',
        name: 'detailsContribuables',
        component: ContribuableDetails
    },
    {
        path: '/contribuables/profile/:id',
        name: 'ficheContribuables',
        component: FicheContribuable
    },
    {
        path: '/contribuables/change-secteur/:ids',
        name: 'contribuableChangeSecteur',
        component: ChangeSecteur
    },
    {
        path: '/contribuables/edit/:id',
        name: 'editContribuables',
        component: EditContribuable
    },
    {
        path: '/contribuables/remboursement/:id',
        name: 'remboursementContribuable',
        component: Remboursement
    },
    {
        path: '/contribuables/individuel/:id',
        name: 'ficheIndividuelle',
        component: FicheIndividuelle
    },
]