import Impayes from "@/components/releves/Impayes";
import MoyensDePaiements from "@/components/releves/MoyensDePaiements";
import TDRL from "@/components/releves/TDRL.vue";
import EquipementImpaye from "@/components/releves/EquipementImpaye.vue";
import EquipementPaye from "@/components/releves/EquipementPaye.vue";
import ListeEquipement from "@/components/releves/ListeEquipement.vue";
import Facture from "@/components/releves/Facture.vue";

export default [
    {
        path: '/releves/impayes',
        name: 'impayes',
        component: Impayes
    },
    {
        path: '/releves/moyen-paiement',
        name: 'moyen-paiement',
        component: MoyensDePaiements
    },
    {
        path: '/releves/tdrl',
        name: 'releveTdrl',
        component: TDRL
    },
    {
        path: '/releves/liste-equipement-impayes',
        name: 'releve-equipements-impayes',
        component: EquipementImpaye
    },
    {
        path: '/releves/liste-equipement',
        name: 'releve-liste-equipement',
        component: ListeEquipement
    },
    {
        path: '/releves/liste-equipement-paye',
        name: 'releve-equipements-payes',
        component: EquipementPaye
    },
    {
        path: '/releves/facture',
        name: 'releve-facture',
        component: Facture
    },


]