import listRamification from "@/components/ramifications/listRamification";
import CreateRamification from "@/components/ramifications/CreateRamification";
import EditRamification from "@/components/ramifications/EditRamification";


export default [

    {
        path: '/ramifications',
        name: 'listRamifications',
        component: listRamification
    },
    {
        path: '/ramifications/create',
        name: 'createRamifications',
        component: CreateRamification
    },
    {
        path: '/ramifications/create/:id',
        name: 'createRamification',
        component: CreateRamification
    },
    {
        path: '/ramifications/:id',
        name: 'editRamifications',
        component: EditRamification
    }
]