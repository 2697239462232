import ListeEquipement from "@/components/equipement/ListeEquipement";
import FicheEquipement from "@/components/equipement/FicheEquipement";


export default [

    {
        path: '/equipements',
        name: 'listeEquipements',
        component: ListeEquipement
    },
    {
        path: '/equipements/fiche/:id',
        name: 'ficheEquipements',
        component: FicheEquipement
    }
]