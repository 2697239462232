import ListeUtilisateur from "@/components/utilisateurs/ListeUtilisateur";
import CreateUtilisateur from "@/components/utilisateurs/CreateUtilisateur";
import EditUtilisateur from "@/components/utilisateurs/EditUtilisateur";


export default [
    {
        path: '/users',
        name: 'listUsers',
        component: ListeUtilisateur
    },
    {
        path: '/users/create',
        name: 'createUser',
        component: CreateUtilisateur
    },
    {
        path: '/users/:id',
        name: 'editUser',
        component: EditUtilisateur
    }
]