import API from '@/helpers/Api';

export default class Pays {
    id;
    nom;
    description;
    enabled;
    createdAt;
    updateAt;
    api = new API();
    constructor() {
        this.id = null;
        this.description = null;
        this.enabled = true;
        this.nom = null
    }
}