import API from "@/helpers/Api";

export default class TypeRamification {

    id;
    nom;
    description;
    createdAt
    updatedAt
    enabled = false;
    api = new API();

    constructor() {
        this.id = null;
        this.description = null;
        this.categorie = null;
        this.nom = null;
        this.enabled = false;

    }


  
     

}