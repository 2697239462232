<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Agents</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Administration</router-link></li>
            <li class="breadcrumb-item active">Agents</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <form @submit.prevent="createCollectivite">
      <div class="card">
      <div class="card-body">
        <div class="row text-start">
          <div class="col-sm-12">
            <label for="firstName" class="form-label">Collectivité</label>
            <select v-model="user.entites" class="form-control form-select">
              <option :value="item.id" v-for="item in listCollectivites" :key="item">{{item.nom}}</option>
            </select>
            <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
          </div>

        </div>
        <div class="row mt-2 text-start">
          <div class="col-sm-6">
            <label for="firstName" class="form-label">Nom</label>
            <input v-model="user.nom" type="text" class="form-control" required>
            <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
          </div>
          <div class="col-sm-6">
            <label for="firstName" class="form-label">Prenom</label>
            <input v-model="user.prenom" type="text" class="form-control" required>
            <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
          </div>
        </div>
        <div class="row mt-2 text-start">
          <div class="col-sm-6">
            <label for="firstName" class="form-label">Adresse E-mail</label>
            <input v-model="user.email" type="text" class="form-control" required>
            <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
          </div>
          <div class="col-sm-6">
            <label for="firstName" class="form-label">Nom d'utilisateur</label>
            <input v-model="user.username" type="text" class="form-control" required>
            <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
          </div>
        </div>
        <div class="row mt-2 text-start">
          <div class="col-sm-6">
            <label for="firstName" class="form-label">Mot de passe</label>
            <input v-model="user.password" type="password" class="form-control" required>
            <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
          </div>
          <div class="col-sm-6">
            <label for="firstName" class="form-label">Repetez le mot de passe</label>
            <input v-model="user.passwordConfirm" type="password" class="form-control" required>
            <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
          </div>
        </div>
        <div class="row mt-2 text-start">
          <div class="col-sm-12">
            <label for="firstName" class="form-label">Affectation</label>
            <select v-model="user.ramifications" class="form-control form-select" multiple>
              <option :value="item.id" v-for="item in listRamifications" :key="item">{{item.nom}}</option>
            </select>
            <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="form-check form-switch form-switch-success">
              <input  v-model="user.enabled" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" >
              Active ?
            </div>
          </div>
        </div>

      </div>
      <div class="card-footer">
          <div class="row">
            <button type="submit" class="btn btn-primary">Enregistrer</button>

          </div>
        </div>

      </div>
    </form>
  </div>
</template>
<script>
import User from "@/entity/User";
import UserRepository from "@/repository/UserRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import RamificationRepository from "@/repository/RamificationRepository";
export default {
  name: 'createAgentPage',
  data(){
    return{
      user: new User(),
      repository: new UserRepository(),
      entiteRepo: new EntiteRepository(),
      ramificationRepo: new RamificationRepository(),
      listCollectivites: [],
      listRamifications: []
    }
  },
  methods: {

    createCollectivite(){
      this.user.entites = this.user.entites.toString().split('');
      this.user.type = 2;
      this.repository.create(this.user)
          .then(response => {
            if (response.id)
              this.$router.push({ name: 'listAgent' })

          })
    }
  },
  created() {

    this.entiteRepo.search([{colonne: '', operator: '', value: ''}], 'ent.nom', 'asc', '30', 1)
        .then(response =>{
          this.listCollectivites = response.data;
        })
    this.ramificationRepo.search([{colonne: '', operator: '', value: ''}], 'ent.nom', 'asc', '400', 1)
        .then(response =>{
          this.listRamifications = response.data;
        })
  }
}
</script>