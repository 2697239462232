import API from "@/helpers/Api";

export default class Frequence {

    id;
    nom;
    description;
    enabled = false;
    api = new API();

    constructor() {
        this.id = null;
        this.description = null;
        this.nom = null;
        this.enabled = false;
    }

}