import ListeMoyenPaiement from '@/components/moyen_paiement/ListeMoyenPaiement';
import CreateMoyenPaiement from '@/components/moyen_paiement/CreateMoyenPaiement';
import EditMoyenPaiement from '@/components/moyen_paiement/EditMoyenPaiement';

export default [
    {
        path: '/moyen_paiements',
        name: 'listeMoyenPaiement',
        component: ListeMoyenPaiement
    },
    {
        path: '/moyen_paiements/create',
        name: 'createMoyenPaiement',
        component: CreateMoyenPaiement
    },
    {
        path: '/moyen_paiement/:id',
        name: 'editMoyenPaiement',
        component: EditMoyenPaiement
    }
]