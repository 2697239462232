import API from '@/helpers/Api';
import User from '@/entity/User';
//import store from '@/store';

export default class PaysRepository extends API {
    user = new User();

    orderByOptions = [
        { text: 'id', value: 'id', name: 'p.id' },
        { text: 'nom', value: 'nom', name: 'p.nom' },
        { text: 'description', value: 'description', name: 'p.description' },
        { text: 'createdAt', value: 'createdAt', name: 'p.createdAt' },
        { text: 'updatedAt', value: 'updatedAt', name: 'p.updatedAt' },
        { text: 'enabled', value: 'enabled', name: 'p.enabled' },
    ];
    create(data){
        const pays = this.post('/v2/pays', data)
        return pays;
    }
    edit(data, id){
        const pays = this.patch('/v2/pays/'+id, data)
        return pays;
    }
    find(id){
        const pays = this.findOne('/v2/pays/'+id)
        return pays;
    }
    supprimer(id){
        const pays = this.delete('/v2/pays/'+id)
        return pays;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {

        const pays = this.listing('/v2/pays', fields, orderBy, sortOrder, itemPerPage, currentPage)
        //console.log(pays);
        return pays;
    }

}