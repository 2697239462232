import ListRoles from "@/components/roles/ListRoles";
import RolePermissions from "@/components/roles/RolePermissions";
import EditRoleUser from "@/components/roles/EditRoleUser";
import CreateRoleUser from "@/components/roles/CreateRoleUser";


export default [

    {
        path: '/roles',
        name: 'listRoles',
        component: ListRoles
    },
    {
        path: '/roles/:id/permissions',
        name: 'rolePermissions',
        component: RolePermissions
    },
    {
        path: '/roles/:id',
        name: 'editRoleUser',
        component: EditRoleUser
    },
    {
        path: '/roles/create',
        name: 'createRoleUser',
        component: CreateRoleUser
    }
]