import ListeVille from "@/components/villes/ListeVille.vue";
import CreateVille from "@/components/villes/CreateVille.vue";
import EditVille from "@/components/villes/EditVille.vue";


export default [

    {
        path: '/villes',
        name: 'listeVilles',
        component: ListeVille
    },
    {
        path: '/villes/create',
        name: 'createVilles',
        component: CreateVille
    },
    {
        path: '/villes/edit/:id',
        name: 'editVilles',
        component: EditVille
    },

]