import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueQRCodeComponent from 'vue-qrcode-component'
window.$ = window.jQuery = require("jquery");

import 'flatpickr/dist/flatpickr'
import 'flatpickr/dist/flatpickr.css'
import './assets/libs/jsvectormap/css/jsvectormap.min.css'
import './assets/css/bootstrap.min.css'
import VueApexCharts from 'vue3-apexcharts'
import 'bootstrap'
import './assets/libs/swiper/swiper-bundle.min.css'
import './assets/css/icons.min.css'
import './assets/css/app.min.css'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import 'vue'
createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .component('qr-code',VueQRCodeComponent)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyBTtTLgJj1xVkmmEA8EX37YNL7VcIJbkK0',
        },
    })

    .component('apexchart', VueApexCharts)
    .mount('#app')
