<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Changement secteur</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Collectivité</router-link></li>
            <li class="breadcrumb-item active">Contribuables</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <!--<div class="row">
    <div class="col-md-12">
      <h5 style="float:left">Contribuables</h5>
    </div>
  </div>-->

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <form @submit.prevent="modifier"> <!--@submit.prevent="editTypeEquipement"-->
          <div class="card">
            <div class="card-body">
              <Select2 :options="listRamifications"  v-model="ramification">
              </Select2>
            </div><!-- end card-body -->
            <div class="card-footer">
              <button class="btn btn-primary" type="submit">Enregistrer</button>
            </div>
          </div><!-- end card -->


        </form>

      </div>
    </div>
  </div>
</template>

<script>
import RamificationRepository from "@/repository/RamificationRepository";
import ContribuableRepository from "@/repository/ContribuableRepository";
import Select2 from "vue3-select2-component";
export default {
  name: 'ChangeSecteur',
  data(){
    return {
      toEdit: null,
      ok: null,
      ramification: null,
      listRamifications: null,
      ramificationRepo: new RamificationRepository(),
      repository: new ContribuableRepository()
    }
  },
  methods: {
    modifier()
    {
      if (confirm("Etes-vous sur de vouloir modifier ces contribuables")){
        this.repository.findOne("/v2/contribuables/enrollement/change-secteur?ids="+this.toEdit + "&ramification="+this.ramification)
            .then(response => {
              this.ok = response
              this.$router.push({name: 'listeContribuables'})
            })

      }

    }
  },
  created() {
    this.toEdit = JSON.parse(this.$route.params.ids);
    console.log(this.toEdit)
    this.ramificationRepo.search([{ colonne: 'p.id', operator: 'is not null', value: ''  }], 'ram.nom', 'asc', 3000, 1)
        .then(response => {
          this.listRamifications = response.data.map(item => ({id: item.id, text: item.nom + ' - '+ item.parent.nom}));
          //le.log(this.listCollectivite);
        })
  },
  components:{
    Select2
  }
}
</script>